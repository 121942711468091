import Restful from 'jsf/restful.js';
//import utils from 'jsf/utils.js';
import TagBox from 'devextreme/ui/tag_box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from "devextreme/data/data_source"

var RayAssign = (function () {
    function RayAssign(element, options) {
        this.tagbox = null;
        this.dataSource = null;
        this.customStore = null;
        this.pageSize = 0;
        this.placeholder = null;
        this.isSortForced = false;
        this.valueMember = "Value";
        this.displayMember = "Name";
        this.element = element;
        this.options = options;
        this._create();
    };

    RayAssign.prototype._create = function () {
        const self = this;
        //$("ul", this.element).remove();
        this.placeholder = $("input", this.element).attr("placeholder");

        $("input", this.element).remove();
        //$(".rayassign-list", this.element).remove();

        this.pageSize = this.options.controlState.IsAsync ? this.options.controlState.MaxResults : 0;
        this.isSortForced = this.options.controlState.IsSortForced;
        this.initDataSource();
        this.createTagBox();

        if (this.options.controlState.SelectParams !== null)
            this.initFieldSelector();

        if (payload.currentLocale.IsRightToLeft)
            this.element.addClass('k-rtl');

        $(document).ready(function () {
            // 
            // We need to call setBehaviour again to remove the class dx-tag-remove-button (to remove the 'x' on tag).
            // Timeout added otherwise, tags are not ready
            //
            setTimeout(function () {
                self.setBehaviour(self.options.controlState);
            }, 300);
        });
    };
    RayAssign.prototype.createTagBox = function () {
        const self = this;
        const tagboxContainer = document.createElement('div');
        this.element.append(tagboxContainer);
        //
        // Ref: https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxTagBox/Configuration/
        //
        this.tagbox = new TagBox($(tagboxContainer), {
            valueExpr: this.valueMember,
            displayExpr: this.displayMember,
            applyValueMode: "useButtons",
            dataSource: this.dataSource,
            //maxDisplayedTags: 10,
            placeholder: this.placeholder,
            searchEnabled: true,
            searchTimeout: 500,
            selectAllMode: "allPages",
            showDataBeforeSearch: true,
            showDropDownButton: true,
            showMultiTagOnly: false,
            showSelectionControls: true,
            deferRendering: true, 
            itemTemplate(data) {
                return `<div class='custom-item' title='${data.Name}'>${data.Name}</div>`;
            },
            onValueChanged: function (e) {
                // Trigger that the control has changed otherwise, no change is saved
                //console.log("MSDropdown changed", self.options.controlState.Id, e);
                if (e.event)
                    self.options.rayControlChanged(self.element, self.options.controlState);
            }
        });

    };
    RayAssign.prototype.initDataSource = function () {
        const _this = this;
        //
        // Ref: https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/
        //
        _this.customStore = new CustomStore({
            key: this.valueMember,
            useDefaultSearch: true,
            loadMode: 'raw',
            load: function (options) {
                var deferred = $.Deferred();

                _this.readData(options)
                    .done(function (resultData) {
                        let items = resultData.d;
                        deferred.resolve(items);
                    })
                    .fail(function () {
                        return options.error();
                    });

                return deferred.promise();
            }
        });
        //
        // Ref: https://js.devexpress.com/Documentation/ApiReference/Data_Layer/DataSource/
        //
        _this.dataSource = new DataSource({
            pageSize: _this.pageSize,
            paginate: _this.options.controlState.IsAsync,
            store: _this.customStore
        });
    };
    RayAssign.prototype.getValue = function () {
        const self = this;
        const values = [];

        if (self.tagbox._selectedItems.length > 0) {
            self.tagbox._selectedItems
                .forEach(function (item, index) {
                    values.push(item[self.valueMember]);
                });
        }

        return values;
    };
    RayAssign.prototype.setState = function (controlState) {
        this.setValue(controlState);
        this.setBehaviour(controlState);
        this.setCaption(controlState);
        if (!this.options.controlState.IsAsync || this.options.controlState.DoInit === true) {
            this.dataSource.reload();
        }
    };
    RayAssign.prototype.setCaption = function (controlstate) {
        if (controlstate.Caption != null)
            this.element.find("label:first").html(controlstate.Caption);
    };
    RayAssign.prototype.setValue = function (controlState) {
        const self = this;
        const values = [];

        if (controlState.Value != null && controlState.Value.length > 0) {
            controlState.Value
                .forEach(function (item, index) {
                    values.push(item[self.valueMember]); // Tagbox accept list of Ids, otherwise, items are not selected in dropdown
                });
        }
        this.tagbox.option("value", values);
    };
    RayAssign.prototype.setBehaviour = function (controlState) {
        //
        // Manage Enable/Disable
        // - The scrollbar wasn't available when we disabled the tagbox so, I set the tagbox as readonly and mimic
        //   the disable appearance
        //
        this.tagbox.option("readOnly", !controlState.Behavior.Enabled);
        if (controlState.Behavior.Enabled) {
            this.element.find(".tag-remove-button").addClass("dx-tag-remove-button");
            this.element.find(".dx-tag-remove-button").removeClass("tag-remove-button");
            this.element.find(".dx-tag-content").removeClass("dx-state-readonly");
            this.element.find("[data-dx_placeholder]").addClass("dx-placeholder");
        } else {
            //
            // Note: tag-remove-button is not a real class, it's just a placeholder.
            //       Class dx-tag-remove-button is the unique class on the element, if I remove it without adding a placeholder,
            //       we'll not be able to retrieve it
            //
            this.element.find(".dx-tag-remove-button").addClass("tag-remove-button");
            this.element.find(".tag-remove-button").removeClass("dx-tag-remove-button");
            this.element.find(".dx-tag-content").addClass("dx-state-readonly");
            this.element.find("[data-dx_placeholder]").removeClass("dx-placeholder");
        }
        //
        // Manage visibility
        //
        if (this.options.controlState.Behavior.Visible)
            this.element.show();
        else
            this.element.hide();
    };
    RayAssign.prototype.requiredFieldValidator = function () {
        if (this.getValue() !== null && this.getValue().length > 0) {
            this.element.find(".dx-texteditor-container").removeClass("rayAssignSpanValidation");
            return true;
        } else {
            this.element.find(".dx-texteditor-container").addClass("rayAssignSpanValidation");
            return false;
        }
    };
    RayAssign.prototype.initFieldSelector = function () {
        console.log('initFieldSelector');

        const oldButton = this.element.find(".k-select:first").hide();
        this.button = $("<span tabindex=\"-1\" unselectable=\"on\" class=\"k-select\"><span unselectable=\"on\" class=\"k-icon k-i-restore\" role=\"button\" tabindex=\"-1\">select</span></span>");
        this.button.insertBefore(oldButton);
        this.button.off('click mousedown keydown taphold touch')
            .on('click taphold touch tap', $.proxy(this.onFieldSelectorButtonClick, this));
    };
    RayAssign.prototype.showFieldSelector = function () {
        console.log('showFieldSelector');

        const _this = this;
        const selectParams = this.options.controlState.SelectParams.replace('@nestedClientId', this.options.controlState.Id);
        const params = {
            nested: JSON.parse(selectParams),
            parentBpmsAppForm: this.options.bpmsAppForm
        };
        params.parentBpmsAppForm
            .saveFormObject()
            .done(function () {
                $('<div id="modalDialog" />').bpmsAppForm(params);
            }).fail(function (e, status, thrownError) {
                console.error(`Field selector error ${_this.options.controlState.Id}`, e);
            });
    };

    RayAssign.prototype.readData = function (options) {
        const params = {
            keywords: (options.filter === undefined) ? '' : options.filter.map(x => x[2]).join(" "),
            controlId: this.options.controlState.Id,
            pageInstance: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            take: (options.take === undefined) ? 0 : options.take,
            skip: (options.skip === undefined) ? 0 : options.skip,
            isSortForced: this.isSortForced
        };

        return Restful.get('api/control/getitems', params, { 'pageInstanceId': params.pageInstance });
    };

    RayAssign.prototype.onFieldSelectorButtonClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.options.controlState.Behavior.Enabled)
            this.showFieldSelector();
    };

    return RayAssign;
}());

$.widget('ui.rayAssignControl', $.ui.rayControl, {
    assign: null,
    validators: [],
    _create: function () {
        this.validators = [];
        this.assign = new RayAssign(this.element, this.options);
    },
    setState: function (controlState) {
        this.assign.setState(controlState);
        this.setHelp(controlState);
        this._setEnable(controlState.Behavior.Enabled);
    },

    _setEnable: function (enable) {
        if (enable) {
            this.element.removeClass('readonlyField')
            this.element.removeAttr('readonly');
        } else {
            this.element.addClass('readonlyField')
            this.element.attr('readonly', 'readonly');
        }
    },
    requiredFieldValidator: function () {
        return this.assign.requiredFieldValidator();
    },
    getValue: function () {
        return this.assign.getValue();
    }
});
