import utils from 'jsf/utils.js';
import { RayDrawingCanvas } from 'jsf/controls/ray.controls.drawingcanvas.js';
import RichTextEditor from 'jsf/CKEditor.js';
import DateBox from "devextreme/ui/date_box";
import CheckBox from "devextreme/ui/check_box";
import RadioGroup from "devextreme/ui/radio_group";
import TabPanel from "devextreme/ui/tab_panel";
import Accordion from "devextreme/ui/accordion";
import TextBox from "devextreme/ui/text_box";
import NumberBox from "devextreme/ui/number_box";
import TextArea from "devextreme/ui/text_area";

const DevExpress = require("devextreme/bundles/modules/core");
require("devextreme/integration/jquery");
import dateFormat from "dateformat";

//import HighchartsTheme from 'jsf/lib/graphs/ChartThemes/highChartTheme.js';

$.widget('ui.rayModal',
    {
        options: {
            preventOpen: false,
            onClose: null,
            onClosed: null,
            title: "",
            modelKey: "",
            width: "col-lg-6",
            height: "",
            parent: null,
            onCreated: $.noop,
            onOpened: $.noop,
            hiddenFooter: false,
            mode: "",
            closedialog: true
        },

        _create: function () {            
            let self = this;

            if (this.options.modalKey === "" || this.options.modalKey === null || typeof this.options.modalKey === "undefined")
                this.options.modalKey = `modal_key_${utils.rayGuid()}`;

            this.modalElement = $(`
<div class="modal fade" role="dialog" id="${this.options.modalKey}" >
  <button class="cancelBtn" type="button" class="close" style="display: none; position: fixed; top: 10px; right: 10px;" data-dismiss="modal">&times;</button>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-left" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">${this.options.title}</h4>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>`);

            if (typeof this.options.width === "number")
                this.modalElement.find(".modal-dialog").attr('style', `width:${this.options.width.toString()}px !important;`);
            else if (typeof this.options.width === 'undefined')
                this.modalElement.find(".modal-dialog").addClass("modal-lg");
            else {
                this.modalElement.find(".modal-dialog").addClass(this.options.width);
                this.modalElement.find(".modal-dialog").addClass('my-col');
            }

            this.modalElement.find(".modal-dialog").height(this.options.height);

            if (this.options.hiddenFooter)
                this.modalElement.find('.modal-footer').hide();

            $(document.body).append(this.modalElement);

            this.element.appendTo(this.modalElement.find(".modal-body"));
            this.modalElement.find(".modal-body").append("<div style='clear: both'></div>");

            let footer = this.modalElement.find('.modal-footer');
            if (this.options.buttons !== null && typeof this.options.buttons !== "undefined") {
                let buttonCounter = Object.keys(this.options.buttons).length;
                let w = 100 / buttonCounter - 5;
                for (let btn in this.options.buttons) {
                    let callback = this.options.buttons[btn];
                    $(`<button class='btn btn-primary' style='width: ${w}%'>${btn}</button>`).click(callback).appendTo(footer);
                }
            }

            if (!this.options.preventOpen) {
                this.modalElement.modal();
                this.options.onOpened();
            }

            this.modalElement.on("hide.bs.modal", function (e) {
                //console.log("form on hide.bs.modal", self.options.mode);
                if (self.options.closedialog && self.options.mode && (self.options.mode == "edit" || self.options.mode == "add")) {
                    if (window.confirm(i18n.$t("close_form_warning"))) {
                        if (typeof self.options.onClose === "function")
                            if (!self.options.onClose(e, this.options))
                                e.preventDefault();
                    }
                    else {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        return false;
                    }
                }
                else {
                    if (typeof self.options.onClose === "function")
                        if (!self.options.onClose(e, this.options))
                            e.preventDefault();
                }
            });

            this.modalElement.on("hidden.bs.modal", function () {
                //console.info("Remove window.onbeforeunload");
                window.onbeforeunload = null;
                if (typeof self.options.onClosed === "function")
                    self.options.onClosed();
                self.modalElement.remove();
            });

            // on scroll close all open kendo UI popups - otherwise they stay sticky on the screen with absolute position
            $(`#${this.options.modalKey}`).on("scroll", function () {              
                $(document.body).find("[data-role=popup]").kendoPopup("close");
            });

            self.options.onCreated();

        }, // end _create

        close: function () {
            console.log("raymodal close", this.options);
            this.options.closedialog = false;
            this.modalElement.modal('hide');
        },

        destory: function () {
            console.log("raymodal destroy", this.options);
            this.close();
        },

        rebind: function (options) {
            if (typeof options.width === "number")
                this.modalElement.find(".modal-dialog").attr('style', `width:${options.width.toString()}px !important;`);
            else if (typeof this.options.width !== null)
                this.modalElement.find(".modal-dialog").addClass(options.width);

            let title = options.title;
            if (options.nested && typeof options.nested !== "undefined" && options.nested !== null)
                title = options.nested.title;

            if (title !== null && title !== "")
                this.modalElement.find(".modal-title").text(title);
            this.modalElement.find(".modal-dialog").height(this.options.height);
        }
    });

(function ($, undefined) {
    $.widget('ui.rayControl', {
        options: {
            controlState: null,
            bpmsAppForm: null
        },

        validators: [],

        setState: function (cs) {
            if (typeof cs === "undefined" || cs === null) return;
            this._setValue(cs.Value);
            this._setEnable(cs.Behavior.Enabled);
            this._setTabIndex(cs.Behavior.Enabled);
            this._setVisible(cs.Behavior.Visible);
            this.setCaption(cs);
        },

        getCaptionElement: function () {
            return this.element.find("label:first");
        },

        isHTML: function (str) {
            const a = document.createElement('div');
            a.innerHTML = str;

            for (let c = a.childNodes, i = c.length; i--;) {
                if (c[i].nodeType == 1) return true;
            }

            return false;
        },

        setHelp: function (cs) {
            const label = this.getCaptionElement();
            let TooltipAttribute = `data-tooltip-${payload.currentLocale.Language}`;

            let help = this.element.attr(TooltipAttribute);
            if (!help) help = this.element.attr("data-tooltip");

            if (typeof help !== 'undefined' && help !== null && help !== '') {
                //console.log("setHelp", cs.Id, this.element, label, this.element.find("label:first"), help);
                help = help.replace(/\'/g, "&#39;");
                label.find('.tooltipicon').remove();
                const helpSpan = $(`<span class='tooltipicon' data-html='true' data-placement='auto' data-trigger='hover' data-toggle='popover' data-content='${help}'>&nbsp;<i class='fad fa-lg fa-question-circle' style="color: #2993f0;"></i></span>`);
                label.append(helpSpan);
                helpSpan.popover();

                // quick and dirty code to check if help is html or not
                if (!this.isHTML(help))
                    helpSpan.data('bs.popover').tip().addClass('control-help-popover');
            }
        },

        setCaption: function (cs) {
            const caption = cs.Caption;
            const label = this.getCaptionElement();
            if (caption !== null)
                label.html(caption);
            this.setHelp(cs);
        },

        _setValue: function (value) {
            if (typeof this.input == "undefined")
                this.element.val(value);
            else
                this.input.val(value);
        },

        _setEnable: function (enable) {
            const inp = typeof this.input != "undefined" ? this.input : this.element;
            if (enable) {
                inp.removeAttr('readonly');
                inp.removeAttr('disabled');
            } else {
                inp.attr('readonly', 'readonly');
                //inp.attr('disabled', 'disabled');
            }
            this._setEnableDefault(this.element, enable);

        },

        _setEnableDefault: function (el, enable) {
            if (enable) {
                el.removeClass('readonlyField')
            } else {;
                el.addClass('readonlyField')
            }
        },

        _setTabIndex: function (enable) {
            const inp = typeof this.input != "undefined" ? this.input : this.element;
            if (enable) {
                const orginalTabIndex = inp.attr('orginalTabIndex');

                if (typeof orginalTabIndex !== "undefined")
                    inp.attr('tabindex', orginalTabIndex);
            } else {
                const tabIndexAttr = inp.attr('tabindex');

                if (typeof tabIndexAttr !== "undefined") {

                    const orginalTabIndex = inp.attr('orginalTabIndex');

                    if (orginalTabIndex)
                        inp.attr('orginalTabIndex', orginalTabIndex);
                    else {
                        inp.attr('orginalTabIndex', tabIndexAttr);
                    }

                    inp.attr('tabindex', '-1');
                }
            }
        },

        _setVisible: function (visible) {
            //this.element.css('visibility', visible ? 'visible' : 'hidden');
            this.element.css('display', visible ? '' : 'none');
        },

        _dealWithValidation: function () {
            //Do nothing in here, override this method in control where validation is needed on the creation
        },

        getValue: function () {
            const cs = this.options.controlState;
            if (!(cs.Behavior.Enabled && cs.Behavior.Visible))
                return null;

            return this.getControlValue();
        },

        getControlValue: function () {
            return (typeof this.input === 'undefined' || this.input == null) ? this.element.val() : this.input.val();
        },

        clearValidators: function () {
            this.validators = [];
            this.element.find('.dx-tagbox').removeClass('required');
            this.element.find('input').removeClass('required');
            this.element.find('textarea').removeClass('required');
            this.element.find('div.ajaxControl.RayRichTextBox').removeClass('required');
        },

        setValidator: function (validator) {
            const vldt = {
                type: validator.Type,  // 0: RequiredFieldValidator, 1: RangeValidator
                message: validator.Text,
                controlId: validator.ControlId,
                controlTypeString: validator.ControlTypeString
            };
            //console.log("setValidator", this.options.controlState.Id, vldt.type, this.element.find('input'));
                
            if (vldt.type === 0) {
                this.element.find('.dx-tagbox').addClass('required');
                this.element.find('input').addClass('required');
                this.element.find('textarea').addClass('required');
                this.element.find('div.ajaxControl.RayRichTextBox').addClass('required');
                //this.element.find('.dx-tagbox').addClass('rayAssignDivRequired');
            } else if (vldt.type === 1) {
                vldt.minValue = validator.MinValue;
                vldt.maxValue = validator.MaxValue;
            }
            //Override existing validator because new can be updated
            var existing = this.validators.find(x => x.type == vldt.type && x.controlId == vldt.controlId);
            if (existing) { this.validators.splice(this.validators.indexOf(existing), 1); }
            this.validators.push(vldt);
            this._dealWithValidation();
        },

        // TODO: Refactor
        isValidate: function () {
            const result = {
                isValid: true,
                messages: []
            };

            //if (this.validators && this.validators.length > 0)
            //    console.log('isValidate', this.options.controlState.Id, this.validators);

            for (const vldt of this.validators) {
                if (this.options.controlState.Id === vldt.controlId) {
                    //console.log('isValidate', this.options.controlState.Id, vldt);
                    if ((vldt.type == 0 && !this.requiredFieldValidator(vldt)) || (vldt.type == 1 && !this.rangeValidator(vldt))) {
                        if (!this.element.is('invalid'))
                            this.element.addClass('invalid');
                        result.isValid = false;
                        result.messages.push(vldt);
                    } else
                        this.element.removeClass('invalid');
                }

            }
            return result;
        },

        requiredFieldValidator: function (validator) {           
            const inp = typeof this.input != "undefined" ? this.input : this.element;
            return !!inp.val();
        },

        rangeValidator: function (validator) {
            //let inp = typeof this.input != "undefined" ? this.input : this.element;
            const val = this.input.val();
            return val >= validator.minValue && val <= validator.maxValue;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayRow', $.ui.rayControl, {
        _create: function () {},
        _setState: function () { },
        clearValidators: function () {}
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayColumn', $.ui.rayControl, {
        _create: function () {},
        _setState: function () { },
        clearValidators: function () { }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayTextBox', $.ui.rayControl, {
        textBoxEl: null,
        lastValue: '',
        validators: [],
        input: null,

        _create: function () {
            const self = this;
            this.validators = [];

            const tempEl = document.createElement('div');
            tempEl.classList.add('devExpressTextBox');
            this.element.append(tempEl);

            this.oldInput = this.element.children(':input:first');
            var rowsAttr = this.oldInput.attr('rows');
            var attributes = this.oldInput.prop("attributes");
            this.oldInput.remove();

            if (rowsAttr) {
                this.textBoxEl = new TextArea($(tempEl), { showClearButton: true, inputAttr: { rows: rowsAttr } });
            } else {
                this.textBoxEl = new TextBox($(tempEl), { showClearButton: true });
            }
            this.input = this.element.find(".dx-texteditor-input").first();
            $.each(attributes, function () {
                if (this.name === "class")
                    self.input.addClass(this.value);
                else
                    self.input.attr(this.name, this.value);
            });

            $(this.input).scannerDetection({
                onComplete: (s, e) => self._onblur()
            });

            if (this.options.isPreview)
                return;
            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;
            this._addMask();
            this.setState(this.options.controlState);
            this._on(this.input, {
                'blur': this._onblur,
                'change': this._onblur
            });

            this._on(this.input, {
                'keypress': function (e) {
                    if (self.element.attr("AlphaCharsOnly") === "True") {
                        if (String.fromCharCode(e.which).match(/[a-zA-Z\d]*\s*/gmi).join("").length === 0 && e.which !== 0 &&
                            (e.which < 8 || (e.which > 9 && e.Which < 13) || (e.which > 13 && e.which < 16) || (e.which > 20 && e.which < 27) || (e.which > 27))
                        )
                            e.preventDefault();
                        return;
                    }
                    const regex = new RegExp(/^[a-z\d\-_\s\!\?\.\#\&\@\=\+\"\'\;\:\,\(\)]+$/gmi);
                    const char = String.fromCharCode(e.which);
                    if (self.element.attr("OnlyLatinLetters") === "True" && !regex.test(char)) {

                        const key = e.keyCode || e.which;

                        // Don't validate the input if below arrow, delete and backspace keys were pressed
                        if (key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys
                            return;
                        }

                        e.preventDefault();
                    }
                },
                'paste': function(e) {
                    var pasteData = e.originalEvent.clipboardData.getData('text');
                    if (self.element.attr("AlphaCharsOnly") === "True") {
                        var value = pasteData.replace(/[^A-Za-z0-9 ]/gmi, '');
                        if (typeof self.input == "undefined")
                            self.element.val(value);
                        else
                            self.input.val(value);
                        e.preventDefault();
                    }
                    if (self.element.attr("OnlyLatinLetters") === "True") {
                        var value = pasteData.replace(/[^\x00-\x7F]/g, '');
                        if (typeof self.input == "undefined")
                            self.element.val(value);
                        else
                            self.input.val(value);
                        e.preventDefault();
                    }
                }
            });
        },

        setState: function (cs) {
            if (typeof cs === "undefined" || cs === null) return;
            this._setValue(cs.Value);
            this._setEnable(cs.Behavior.Enabled);
            this._setTabIndex(cs.Behavior.Enabled);
            this._setVisible(cs.Behavior.Visible);
            this.setCaption(cs);
            this._setPlaceholder(cs);
        },

        _setValue: function (value) {
            this.lastValue = value;
            if (typeof this.input == "undefined") 
                this.element.val(value);
            else 
                this.input.val(value);

            //console.log("TextBox Set Value", this.options.controlState.Id, value);
            if (value && value.length > 0)
                this.textBoxEl.option('showClearButton', true);
            else
                this.textBoxEl.option('showClearButton', false);
        },
        _onblur: function () {
            const currentVal = this.input.val();
            if (this.lastValue != currentVal) {
                this.options.controlState.Value = this.lastValue = currentVal;
                this._trigger('rayControlChanged', {}, this.options.controlState);
            }
        },

        _setEnable: function (enable) {
            this.textBoxEl.option('readOnly', !enable);
            this._setEnableDefault(this.element, enable);
            this._addMask();
        },

        _addMask: function () {
            const self = this;
            const cs = this.options.controlState;

            if (cs.Behavior.Enabled && cs.Behavior.Visible) {
                const mask = this.element.attr('mask');
                if (mask) {
                    this.input.mask(mask);
                    this.input.on("focus", function () {
                        if (self.input.val() == mask)
                            utils.setSelectionRange(self.input, 0, 1);
                    });
                }
            }
        },
        _setPlaceholder: function (cs) {
            if (cs.Placeholder === null) return;

            if (typeof this.input == "undefined") {
                this.element.attr("placeholder", cs.Placeholder);
            } else {
                this.input.attr("placeholder", cs.Placeholder);
            }
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayLabel', $.ui.rayControl, {

        lastValue: '',
        validators: [],

        _create: function () {
            if (this.options.isPreview)
                return;
            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;
            this.setState(this.options.controlState);
        },
        getCaptionElement: function () {
            return this.element;
        },
        _setEnable: function (enable) {
            this._setEnableDefault(this.element, enable);
        },
        _setVisible: function (visible) {
            this.element.css('display', visible ? '' : 'none');
        },
        _setValue: function (value) {
            if (value != null) {
                const bindId = this.options.controlState.BindingId;
                if (typeof bindId !== "undefined" && bindId != '00000000-0000-0000-0000-000000000000' && bindId != null) {
                    this.element.html(value);
                }
            }
        },
        setState: function (cs) {
            if (this.options.controlState.Color != null || this.options.controlState.Color !== "") {
                $(this.element).css("color", this.options.controlState.Color);
            }
            if (this.options.controlState.BackColor != null || this.options.controlState.BackColor !== "") {
                $(this.element).css("background-color", this.options.controlState.BackColor);
            }
            this._setVisible(cs.Behavior.Visible);
            this._setValue(cs.Value);
            this.setHelp(cs);
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayHelp', $.ui.rayControl, {

        lastValue: '',
        validators: [],

        _create: function () {

            this.element.tooltip();

            if (this.options.isPreview)
                return;
            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;
            this.setState(this.options.controlState);
        },

        _setEnable: function (enable) {
            this._setEnableDefault(this.element, enable);
        },

        _setValue: function (value) {
            if (value)
                this.element.html(value);
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayTime', $.ui.rayControl, {

        lastValue: null,
        validators: [],
        dtbox: null,

        _create: function () {
            const langAttribute = `data-caption-${payload.currentLocale.Language}`;
            const caption = this.element.find('.localizablePlaceholder').attr(langAttribute);
            this.validators = [];

            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;

            const that = this;
            this.dtbox = new DateBox(this.element.find('.dxinput'),
                {
                    type: "time",
                    pickerType: "calendar",
                    displayFormat: "HH:mm",
                    placeholder: caption,
                    rtlEnabled: payload.currentLocale.IsRightToLeft,
                    onValueChanged: function (data) {
                        let currentVal = null;
                        if (Object.prototype.toString.call(data.value) === "[object Date]")
                            currentVal = new Date(data.value).toLocaleTimeString('en-GB', { hour12: false, timeStyle: 'short' }).toString();
                        else if (typeof data.value == 'string' || data.value instanceof String)
                            currentVal = data.value;

                        if (that.lastValue != currentVal) {
                            that.options.controlState.Value = that.lastValue = currentVal;
                            that._trigger('rayControlChanged', {}, that.options.controlState);
                        }
                    }
                });

            this.setState(this.options.controlState);
        },

        _setValue: function (value) {
            if (value && value.length > 1)
                value = '2023-01-01T' + value;
            if (value)
                this.dtbox.option("value", value);
            this.dtbox.option("type", "time");
        },

        getValue: function () {
            //console.log("Time Result", this.options.controlState.id, typeof this.lastValue, this.lastValue);
            if (this.lastValue && this.lastValue.length > 11)
                return this.lastValue.substring(11);
            return this.lastValue;
        },

        _setEnable: function (enable) {
            this.dtbox.option("disabled", !enable);
            this._setEnableDefault(this.element, enable);
        },

        requiredFieldValidator: function (validator) {
            return !!this.lastValue;
        },

        rangeValidator: function (validator) {
            const val = this.lastValue;
            return val >= validator.minValue && val <= validator.maxValue;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayDateTimePicker', $.ui.rayControl, {

        lastValue: null,
        validators: [],
        dtbox: null,
        isArabic: false,
        isUTC: false,
        isDateTime: false,
        dxtype: 'datetime',
        format: null,

        _create: function () {
            //console.log("DateTime init", this.options.controlState.Id);
            const that = this;
            this.validators = [];
            this.isArabic = payload.currentLocale.Culture.toLowerCase() === "ar-sa";

            this.dxtype = 'datetime';
            this.format = `${payload.currentLocale.ShortDateFormat}, ${payload.currentLocale.ShortTimeFormatDP}`;

            if (this.element.hasClass("date")) {
                this.dxtype = 'date';
                this.format = payload.currentLocale.ShortDateFormat;
            } else if (this.element.hasClass('time')) {
                this.dxtype = 'time';
                this.format = payload.currentLocale.ShortTimeFormatDP;
            }

            if (this.element.hasClass('UTC') && this.dxtype !== 'date')
                this.isUTC = true;

            if (this.dxtype == 'datetime')
                this.isDateTime = true;

            const langAttribute = `data-caption-${payload.currentLocale.Language}`;
            const caption = this.element.find('.localizablePlaceholder').attr(langAttribute);

            if (this.options.controlState) {
                if (this.isUTC)
                    this.lastValue = this.options.controlState.Value;
                else if (this.options.controlState.Value && this.options.controlState.Value.length >= 10)
                    this.lastValue = new Date(this.options.controlState.Value.substring(0, 10));
                //console.log(this.options.controlState.Id, "DTP init value", this.options.controlState.Value, this.lastValue);
            }

            if (this.isArabic) {
                this.dtbox = this.element.find(":input:first");
                this.dtbox.calendarsPicker({
                    showTrigger: '<img src="dist/calendar.gif" alt="Popup" class="arabicDatePickerTrigger trigger" >',
                    calendar: $.calendars.instance('islamic', 'ar'),
                    localNumbers: true,

                    onSelect: function (dates) {
                        const currentVal = dates[0].toJSDate();
                        //console.log("DTP changed", dates[0], currentVal);
                        if (that.lastValue != currentVal) {
                            that.options.controlState.Value = that.lastValue = currentVal;
                            that._trigger('rayControlChanged', {}, that.options.controlState);
                        }
                    }
                });
            } else {
                this.element.find(":input:first").remove();
                this.element.append("<div class='dxinput'></div>");
                const isiPad = window.navigator.userAgent.match(/Safari/i) && navigator.vendor.match(/apple/i) && navigator.maxTouchPoints > 1;
                this.dtbox = new DateBox(this.element.find('.dxinput'),
                    {
                        type: this.dxtype,
                        displayFormat: this.format,
                        pickerType: "calendar",
                        placeholder: caption,
                        showClearButton: true,
                        showAnalogClock: false,
                        acceptCustomValue: !isiPad,
                        rtlEnabled: payload.currentLocale.IsRightToLeft,
                        onValueChanged: function (data) {
                            let inpDate = data.value;
                            if (that.dxtype === "date")
                                inpDate = dateFormat(data.value, 'yyyy-mm-dd');
                            let currentVal = new Date(inpDate);
                            if (data.value == null)
                                currentVal = null;
                            if (that.lastValue != currentVal) {
                                that.options.controlState.Value = that.lastValue = currentVal;
                                that._trigger('rayControlChanged', {}, that.options.controlState);
                                //console.log(that.options.controlState.Id, "DTP changed", data.value, inpDate, currentVal, that.options.controlState.Value);
                            }
                        }
                    });
            }
        },

        _init: function () {
        },
        
        _dealWithValidation: function () {
            var _this = this;
            if (_this.validators && _this.validators.length > 0) {
                _this.validators.forEach(function (item) {
                    if (item.controlId === _this.options.controlState.Id && item.controlTypeString == "RayDateTimePicker" && item.type == 1) {
                        if (item.minValue || item.maxValue) {
                            _this.dtbox.on("opened", (e) => _this._hideOrNotTodayButton(e, item));

                            if (item.minValue) {
                                _this.dtbox.option("min", utils.convertTicksToDate(item.minValue));
                            }
                            if (item.maxValue)
                                _this.dtbox.option("max", utils.convertTicksToDate(item.maxValue));


                        }
                    }
                });
            }
        },
        //If current date/time is not in required range, remove button Today
        _hideOrNotTodayButton: function (e, item) {
            var nowInTicks = utils.convertDateToTicks(new Date());
            if ((item.minValue && nowInTicks < item.minValue) || (item.maxValue && item.maxValue < nowInTicks)) {
                e.component._popup.option('toolbarItems', e.component._popup.option('toolbarItems').filter(function (obj) { return obj.options.type !== 'today'; }));
            }
        },

        rangeValidator: function (validator) {
            
            if (this.lastValue) {
                //check old data format first (min ands max date value saved as number , example : 20230413 )  , new format : date is saved as Ticks 
                if (validator.minValue.toString().length == 8 || validator.maxValue.toString().length == 8) {
                    const minDate = fecha.parse(validator.minValue.toString(), 'YYYYMMDD');
                    const maxDate = fecha.parse(validator.maxValue.toString(), 'YYYYMMDD');

                    if (minDate || maxDate) {
                        return this.lastValue >= minDate && this.lastValue <= maxDate;
                    }
                }
                

                var selectedDateTicks = utils.convertDateToTicks(this.lastValue.getTime());

                //const minDate = fecha.parse(validator.minValue.toString(), 'YYYYMMDD');
                //const maxDate = fecha.parse(validator.maxValue.toString(), 'YYYYMMDD');

                return selectedDateTicks >= validator.minValue && selectedDateTicks <= validator.maxValue && this.dtbox.option('isValid');

            }
            return true;
        },

        requiredFieldValidator: function () {
            //console.log("DT validator", this.lastValue, this.lastValue ? this.lastValue.getTime() : -1);
            return !!(this.lastValue && this.lastValue.getTime() > 0);

        },

        _setVisible: function (visible) {
            this.element.css('display', visible ? 'block' : 'none');
        },

        _setEnable: function (enable) {
            //console.log(this.options.controlState.Id, "Set datepicker state", enable);
            if (this.isArabic)
                this.dtbox[0].disabled = !enable;
            else
                this.dtbox.option("disabled", !enable);
            //console.log(this.options.controlState.Id, "Set datepicker state. Success!");
            this._setEnableDefault(this.element, enable);
        },

        _setValue: function (value) {
            if (value) {
                if (this.isArabic) {
                    const d = $.calendars.instance('islamic', 'ar').fromJSDate(new Date(`${value} UTC`));
                    //console.log("DTP set value A", new Date(value + " UTC"), d, d.toString());
                    this.dtbox.calendarsPicker('setDate', d);
                } else {
                    value = value.replace(/\s/, 'T');
                    //console.log(this.options.controlState.Id, "DTP set value", this.isUTC, value, new Date(value), new Date(value.replace('Z', '')));
                    if (this.isUTC) {
                        //console.log(this.options.controlState.Id, "Set datepicker value", `${value}`);
                        this.dtbox.option("value", `${value}`);
                    }
                    else {
                        //console.log(this.options.controlState.Id, "Set datepicker value", value.replace('Z', ''));
                        this.dtbox.option("value", value.replace('Z', ''));
                    }
                }
            } else {
                //console.log(this.options.controlState.Id, "Clear datepicker value");
                this.lastValue = null;
                if (this.isArabic) {
                    this.dtbox.calendarsPicker('setDate', null);
                } else {
                    this.dtbox.option("value", null);
                }
            }
            //console.log(this.options.controlState.Id, "Set datepicker value. Success!");
        },

        getValue: function () {
            //console.log("DateTime Result", this.options.controlState.Id, this.isUTC);
            let result = this.lastValue;
            if (this.isDateTime && !this.isUTC) {
                //console.log("DateTime Result Converted", this.lastValue, this.lastValue.toString().slice(0, 24), new Date(this.lastValue.toString().slice(0, 24) + ' UTC'));
                result = new Date(this.lastValue.toString().slice(0, 24) + ' UTC');
            }
            return result;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayNumericTextBox', $.ui.rayControl, {
        numTextBoxEl: null,
        lastValue: '',
        validators: [],
        input: null,
        currentLocale: payload.currentLocale.Language,
        format: '',
        _create: function () {
            const self = this;

            const tempEl = document.createElement('div');
            tempEl.classList.add('devExpressNumericTextBox');
            this.element.append(tempEl);

            this.oldInput = this.element.children(':input:first');
            var attributes = this.oldInput.prop("attributes");
            this.oldInput.remove();
            var existingFormat = this.oldInput.attr('Format');
            var deFormat = '#';
            if (existingFormat)
                deFormat = existingFormat;

            this.numTextBoxEl = new NumberBox($(tempEl), {
                showClearButton: false,
                showSpinButtons: true,
                format: deFormat,
                value: !self.options.controlState.Value ? self.options.controlState.Value : (typeof self.options.controlState.Value === 'number' ? self.options.controlState.Value : Number(self.options.controlState.Value.replace(',', '.'))  || self.options.controlState.Value),
                onValueChanged: function (e) {
                    if (!self.numTextBoxEl.option('showClearButton')) { self.numTextBoxEl.option('showClearButton', true); }
                }
            });
            this.input = this.element.find(".dx-texteditor-input").first();
            $.each(attributes, function () {
                if (this.name === "class")
                    self.input.addClass(this.value);
                else
                    self.input.attr(this.name, this.value);
            });

            this.validators = [];

            if (this.options.isPreview)
                return;
            $(this.input).scannerDetection({
                onComplete: function (s, e) {
                    self._onblur();
                }
            });
            this.format = this.input.attr('Format');
            
            this._correctMaxLength();

            // this.input.raynumerictextbox();
            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;
            this.setState(this.options.controlState);

            this._on(this.input, {
                'blur': this._onblur
            });

            if (this.format.indexOf(".0") >= 0) {
                this._on(this.input, {
                    'change': this._keyUp
                });
            } else {
                this._on(this.input, {
                    'keyup': this._keyUp
                });
            }

        },

        _getCommasAndDots: function () {
            let dotAndCommaCounts = 0;
            const format = this.format;
            for (let i = 0; i < format.length; i++)
                if (format.charAt(i) === ',' || format.charAt(i) === '.')
                    dotAndCommaCounts++;
            return dotAndCommaCounts;
        },

        _correctMaxLength: function () {
            const dotAndCommaCounts = this._getCommasAndDots();

            if (dotAndCommaCounts != 0) {
                let maxlength = parseInt(this.input.attr("maxlength"));
                maxlength = maxlength + dotAndCommaCounts;
                this.input.attr("maxlength", maxlength);
            }
        },

        _keyUp: function (event) {
            if (this.format.indexOf(".0") >= 0) {
                const count = this._getCommasAndDots();
                let maxlength = parseInt(this.input.attr("maxlength")) - count;
                let value = this.input.val();
                if (isNaN(maxlength))
                    maxlength = value.length;
                value = value.substring(0, maxlength);
                this.input.val(value);
            }

            const $this = this.input;
            const key = event.which;
            const lengtha = $this.val().length;
            if (lengtha === 0)
                return;
            if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key === 8 || key === 46) {
                let position = $this.getCursorPosition();

                if (this.format) {
                    $this.parseNumber({ format: this.format, locale: this.currentLocale});
                    $this.formatNumber({ format: this.format, locale: this.currentLocale });
                }
                const lengthb = $this.val().length;
                if (lengthb > lengtha)
                    position++;
                $this.setCursorPosition(position);
            }
            //this._onblur();
        },

        _onblur: function () {
            const $this = this.input;
            let currentVal = $this.val();
            if (this.format)
                $this.parseNumber({ format: this.format, locale: this.currentLocale });
            if (currentVal)
                currentVal = $this.val();
            if (this.format)
                $this.formatNumber({ format: this.format, locale: this.currentLocale });
            if (this.lastValue != currentVal) {
                this.options.controlState.Value = this.lastValue = currentVal;
                this._trigger('rayControlChanged', {}, this.options.controlState);
            }
        },

        _setValue: function (value) {
            const self = this;
            const el = this.input;
            el.val(value);
            if (value) { self.numTextBoxEl.option('showClearButton', true); }
            this.lastValue = value;

            if (!this.format)
                return;
            el.parseNumber({ format: this.format, locale: this.currentLocale });
            el.formatNumber({ format: this.format, locale: this.currentLocale });
        },
        _setEnable: function (enable) {
            this.numTextBoxEl.option('disabled', !enable);
            this._setEnableDefault(this.element, enable);
        },
        rangeValidator: function (validator) {
            const el = this.input;
            const $this = this.input;
            const currentVal = $this.val();
            if (currentVal != "") {
                const val = parseFloat(el.val()); //el.parseNumber({ format: this.format, locale: "us" });
                //console.log("rangeValidator", val, validator.minValue, validator.maxValue);
                //let val = this.element.val();
                return val >= validator.minValue && val <= validator.maxValue;
            }
        },

        requiredFieldValidator: function (validator) {
            const inp = typeof this.input != "undefined" ? this.input : this.element;
            const num = $('<input/>').formatNumber({ format: this.format, locale: this.currentLocale }).val();
            if (this.format != "" && this.format.indexOf(".") >= 0) {
                if (inp.val() != "" && num != inp.val())
                    return true;
            } else if (this.format.indexOf(",") >= 0 || this.format === "") {
                if (inp.val() != "")
                    return true;
            }

            return false;
        },
        getControlValue: function () {
            return this.lastValue;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayNestedLink', $.ui.rayControl, {
        newButton: null,
        editButton: null,
        deleteButton: null,
        viewButton: null,
        validators: [],
        nestedEl: null,


        _create: function () {
            const self = this;
            const op = this.options;
            const cs = this.options.controlState;
            this.viewButton = this.element.find('[nestedtype="view"]');
            this.newButton = this.element.find('[nestedtype="new"]');
            this.editButton = this.element.find('[nestedtype="edit"]');
            this.deleteButton = this.element.find('[nestedtype="delete"]');
            this.nestedEl = $('<div id="modalDialog" />');
            //Set Behaviour
            this.setState(cs);
            //Set Button Events

            this.newButton.unbind("click").click(function () {
                op.bpmsAppForm.saveFormObject().done(function () {
                    const params = { nested: JSON.parse(cs.Behavior.NewButton.Parameters) };
                    params.nested.editorMode = "add";
                    params.parentBpmsAppForm = op.bpmsAppForm;
                    self.nestedEl.bpmsAppForm(params);

                });
                return false;
            });

            this.editButton.unbind("click").click(function () {
                op.bpmsAppForm.saveFormObject().done(function () {
                    const params = { nested: JSON.parse(cs.Behavior.EditButton.Parameters) };
                    params.nested.editorMode = "edit";
                    params.parentBpmsAppForm = op.bpmsAppForm;
                    self.nestedEl.bpmsAppForm(params);
                });
                return false;
            });

            this.viewButton.unbind("click").click(function () {
                if (op.bpmsAppForm.options.nested && op.bpmsAppForm.options.nested.editorMode == 'view') {
                    const params = { nested: JSON.parse(cs.Behavior.ViewButton.Parameters) };
                    params.nested.editorMode = "view";
                    params.parentBpmsAppForm = op.bpmsAppForm;
                    self.nestedEl.bpmsAppForm(params);
                }
                else
                    op.bpmsAppForm.saveFormObject().done(function () {
                        const params = { nested: JSON.parse(cs.Behavior.ViewButton.Parameters) };
                        params.nested.editorMode = "view";
                        params.parentBpmsAppForm = op.bpmsAppForm;
                        self.nestedEl.bpmsAppForm(params);
                    });
                return false;
            });


            this.deleteButton.unbind("click").click(function () {
                utils.confirm(i18n.$t('messages.confirm_message'), {
                    yes: function () {
                        op.bpmsAppForm.saveFormObject().done(function () {
                            op.bpmsAppForm.deleteNestedObject(cs.Id);
                        });
                    },
                    no: function () {
                        return false;
                    }
                });
            });
        },

        _setEnable: function (enable) {
            this._super(enable);
            const enabled = this.options.controlState.Behavior.Enabled;

            if (this.options.controlState.Value || !(enabled && this.options.controlState.Behavior.NewButton.Enabled))
                this.newButton.hide();
            else
                this.newButton.show();

            if (!this.options.controlState.Value || !(enabled && this.options.controlState.Behavior.DeleteButton.Enabled))
                this.deleteButton.hide();
            else
                this.deleteButton.show();

            if (!this.options.controlState.Value || !(enabled && this.options.controlState.Behavior.EditButton.Enabled))
                this.editButton.hide();
            else
                this.editButton.show();

            //console.log("Nested link enable 1", this.options.controlState.Id, this.options.controlState.Value, this.options.controlState.Behavior.ViewButton.Enabled);
            if (!this.options.controlState.Value || !this.options.controlState.Behavior.ViewButton.Enabled)
                this.viewButton.hide();
            else
                this.viewButton.show();
            this._setEnableDefault(this.element, enable);
        },

        _setVisible: function (visible) {
            this._super(visible);
        },
        //Overrides the default function of RayControl to keep nested form translated
        setCaption: function (cs) {
            this.setHelp(cs);     
        },
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayLine', $.ui.rayControl, {
        _create: function () {
        },
        _setState: function (cs) {
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayRichTextBox', $.ui.rayControl, {
        rtElement: null,
        editor: null,

        _create: function () {
            //console.log("Richtext init", this.options.controlState.Id);
            const self = this;
            this.validators = [];
            const cs = this.options.controlState;
            //SetControlBase(obj, controlState);

            this.rtElement = this.element.find(`#${cs.Id}`);
            //console.log("rich text element", cs.Id, this.rtElement, $(this.rtElement));
            if (!cs.Behavior.Visible) {
                this.element.hide();
                return;
            }

            this.element.resizable({ handles: 's', minHeight: 100 });

            if (cs.Behavior.Enabled) {
                if (cs.Value)
                    this.rtElement.html(cs.Value);

                //RichTextEditor($(this.rtElement), cs, this);
            } else {
                this.rtElement
                    .addClass('ui-corner-all ui-widget-content col-sm-12 disabled')
                    .css('overflow', 'auto')
                    .html(cs.Value);
            }

            //Resize element after html creation (if CKEditor init -> same code will be run again there)
            setTimeout(function () { utils.resizeRichTextBox(self.element, cs.Behavior.Enabled); }, 300);
        },

        _setValue: function (value) {
            const cs = this.options.controlState;
            //console.log("Change richtext value", cs.Id, value.length, this.editor);
            if (this.editor)
                this.editor.setData(value);
            else {
                //$(`#${cs.Id}`).html(value);
                this.element.find(`#${cs.Id}`).html(value);
                utils.resizeRichTextBox(this.element, cs.Behavior.Enabled);
            }

        },

        _setVisible: function (visible) {
            const cs = this.options.controlState;
            //console.log("Set visible", cs.Id, visible, cs.Behavior.Enabled);
            if (visible) {
                this.element.show();
                if (!this.editor && cs.Behavior.Enabled)
                    try {
                        RichTextEditor($(this.rtElement), this.element, cs, this);
                    } catch (ex) {
                    }
            } else
                this.element.hide();

            if (!cs.Behavior.Enabled) {
                this.element.find('.ck-reset_all').hide();
                //console.log("all links", $(`#${cs.Id} a`));
                //$(`#${cs.Id} a`).attr('target', '_blank');
                this.element.find(`#${cs.Id} a`).attr('target', '_blank');
            }
        },

        _setEnable: function (enable) {
            this._super(enable);
            if (!enable) {
                var inlineHeight = this.element.css('height');
                if (inlineHeight) {
                    this.element.css('max-height', inlineHeight);
                    this.element.css('overflow', 'auto');
                    this.element.css('height', '');
                }
            }
            this._setEnableDefault(this.element, enable);
        },

        requiredFieldValidator: function () {
            return this.editor.getData().length > 0;
        },

        getControlValue: function () {
            const cs = this.options.controlState;
            if (!(cs.Behavior.Enabled && cs.Behavior.Visible))
                return null;

            // previously it was
            //     return window.btoa(unescape(encodeURIComponent(this.element.find(`#${cs.Id}`).html())));
            // it's better to use CKEditor.getData() instead of extracting HTML from element
            return window.btoa(unescape(encodeURIComponent(this.editor.getData())));
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayDropDownList', $.ui.rayControl, {
        _create: function () {
            const self = this;
            this.validators = [];
            this._setState(this.options.controlState);
            this.element.change(function () {
                const cs = self.options.controlState;
                cs.Value = self.element.val();
                //if (cs.Behavior.AutoUpdate)
                self._trigger('rayControlChanged', {}, cs);
            });
        },

        _setState: function (cs) {
            const self = this;
            const el = self.element;
            const pageInstanceId = this.options.bpmsAppForm.dto.formState.PageInstanceId;
            let doInit = cs.DoInit;

            this.setCaption(cs);

            if (!doInit) {
                if ($(el).val() != cs.Value) {
                    doInit = true;
                }
            }
            //this._super(cs);
            //Set Value
            const postData = { keywords: "", controlId: cs.Id, pageInstance: pageInstanceId, maxResults: 0, isSortForced: false };
            if (cs.Behavior.Visible && doInit) {
                utils.callWebAPI("api/control/getitems", postData, function (result) {
                    el.empty();
                    const isFilter = el.parent('.RaySearchPanel').length > 0;
                    if (cs.IsNullable || isFilter)
                        el.append("<option value=''></option>");
                    for (let i = 0; i < result.d.length; i++) {
                        el.append(`<option value='${(result.d[i].Value.trim())}'>${result.d[i].Name}</option>`);
                    }
                    if (cs.Value != null)
                        el.val(cs.Value.trim());
                }, function (a, b, c) {                 
                    utils.showAjaxError(a, b, c, cs.Id);
                });
            }
        }
    });
})(jQuery);

(function ($) {
    $.widget("ui.rayAutoCompleteDropDownList", $.ui.rayControl, {
        _offlineDataSource: [],
        input: null,
        button: null,
        pageInstanceId: null,
        MaxItems: null,
        MaxRequest: null,
        Sync: false,
        MinChars: null,
        IsOpen: false,
        IsSortForced: false,

        /* methods */
        _create: function () {
            //console.log("Dropdown init", this.options.controlState.Id);
            const that = this;
            this.validators = [];
            this.input = $(':input:first', this.element);
            this.button = $('button:first', this.element);

            const cs = this.options.controlState;
            this.pageInstanceId = this.options.bpmsAppForm.dto.formState.PageInstanceId;

            that.Max = cs.IsAsync ? cs.MaxResults + 1 : cs.MaxResults;
            that.MaxRequest = cs.MaxResults;
            that.MinChars = cs.MinChars;
            that.IsSortForced = cs.IsSortForced;

            this.element.on('mousedown', 'ul.typeahead', function (e) {
                e.preventDefault();
            });

            if (that.options.controlState.SelectParams) {
                this.button.find('.ui-icon').removeClass('ui-icon-triangle-1-s').addClass('ui-icon ui-icon-newwin');
                if (this.button.find('.glyphicon').length > 0)
                    this.button.find('.glyphicon').removeClass('glyphicon glyphicon-chevron-down').addClass('k-icon k-i-restore');
            }

            this.button.click(function (e) {
                if (!that.options.controlState.SelectParams) {
                    //let selectedValue = that.input.typeahead('getActive');
                    that.input.typeahead('lookup', '').focus();
                } else {
                    const selectParams = cs.SelectParams ? cs.SelectParams.replace('@nestedClientId', that.element.attr('serverid')) : null;
                    const params = { nested: JSON.parse(selectParams) };
                    params.parentBpmsAppForm = that.options.bpmsAppForm;
                    params.parentBpmsAppForm.saveFormObject().done(function () {
                        $('<div id="modalDialog" />').bpmsAppForm(params);
                    }).fail(function (e, status, thrownError) {                
                        utils.showAjaxError(e, status, thrownError, null, false);
                    });
                }
                return false;
            });

            this.input.keydown(function (e) {
                const selectedValue = that.input.typeahead('getActive');
                if ((e.key === 'ArrowDown' && that.input.next('.dropdown-menu').length === 0) ||
                    (e.key === 'ArrowDown' && !that.input.next().is(':visible')))
                    that.input.typeahead('lookup', '').focus();
            });

            this._initAutoComplete();
            //this.element.find('.typeahead').attr('placeholder', i18n.$t('dropdown_control.select'))
        }, // end _create

        getControlValue: function () {
            const selectedValue = this.input.typeahead('getActive');
            if (selectedValue == null || typeof selectedValue == "undefined") return null;
            if (selectedValue.id == null) return null;
            return selectedValue.id;
        },

        setState: function (cs) {
            //console.log("Dropdown setstate", this.options.controlState.Id, cs.DoInit);
            const that = this;
            const pageInstanceId = this.options.bpmsAppForm.dto.formState.PageInstanceId;
            let doInit = cs.DoInit;

            // enable/visible
            this._setBehaviour(cs);
            this.setCaption(cs);
            // determine to load data again
            if (!doInit) {
                const active = that.input.typeahead('getActive');
                if ((active == null || typeof active == "undefined" || active.id != cs.Value) || cs.Value === "")
                    doInit = true;
            }

            // set data sources
            // for async and field selector
            if ((cs.IsAsync || cs.SelectParams) && doInit) {
                if (cs.Behavior.Visible && cs.Value) {
                    that.input.typeahead('setActive', { id: cs.Value.trim(), name: cs.Value.trim() });
                    this._loadSelectedValue(cs);
                }
                else
                    that.input.typeahead('setActive', { id: null, name: '' });
                return;
            }

            // for sync
            if (doInit && cs.Behavior.Visible) {
                // Metronic.blockUI({ target: self.Input, animate: true });
                this._loadOfflineDataSource().done(function () {
                    let found = false;
                    if (cs.Value != null)
                        for (let i = 0; i < that._offlineDataSource.length; i++) {
                            const item = that._offlineDataSource[i];
                            const csVal2 = cs.Value.trim();
                            //console.log("load source", csVal2, item.id, item.name);
                            if (item.id.trim() === csVal2 || item.name.trim() === csVal2) {
                                //console.log("load source !!!", csVal2, item.id, item.name);
                                that.input.typeahead('setActive', { id: cs.Value, name: item.name });
                                found = true;
                                break;
                            }
                        }

                    if (!found)
                        that.input.typeahead('setActive', { id: null, name: null });
                    // Metronic.unblockUI(self.Input);
                    self.Sync = true;
                }).fail(function () {
                    // Metronic.unblockUI(self.Input);
                });
            }
        }, // end setState

        _setBehaviour: function (cs) {
            this.element.find('input,button').prop('disabled', !cs.Behavior.Enabled);

            if (cs.Behavior.Enabled)
                this.element.find('button').removeClass("button-disabled ui-state-disabled");
            else
                this.element.find('button').addClass("button-disabled ui-state-disabled");
            this._setEnableDefault(this.element, cs.Behavior.Enabled);
            this.element.toggle(cs.Behavior.Visible);
        }, // end setBehaviour

        _initAutoComplete: function () {
            //console.log("Dropdown initautocomplete", this.options.controlState.Id);
            const that = this;
            const cs = this.options.controlState;

            this.input.blur(function () {
                try {
                    let val = that.input.val();
                    const active = that.input.typeahead('getActive');

                    val = val.replace(/\s/gmi, '');
                    let name = "";
                    if (active != null && active.name != null)
                        name = active.name.replace(/\s/gmi, '');

                    if (typeof active == "undefined" && active == null || (name != val && name != null)) {
                        that.input.typeahead('setActive', { id: null, name: null });
                        that._trigger('rayControlChanged', {}, cs);
                    }

                } catch (ex) {
                    //
                    console.log(ex);
                }
            });
            this.input.typeahead({
                source: function (query, process) {
                    if (!that.options.controlState.IsAsync && !that.options.controlState.SelectParams)
                        return process(that._offlineDataSource, !that.options.controlState.IsAsync);
                    else
                        that._loadQueryDataSource(query).done(function (result) {
                            that._offlineDataSource = that._transformDataItems(result);
                            //console.log("data loaded", result, that._offlineDataSource);
                            return process(that._offlineDataSource);
                        });
                },
                delay: that.options.controlState.Delay,
                autoSelect: false,
                items: that.Max,
                minLength: that.MinChars,
                //displayText: function (item) {
                //    return '<i class="fad fa-flag-checkered" style="color:green"></i> <span style="color:red">' + item.name + '</span>';
                //},
                afterSelect: function (e) {
                    that.input.val(e.name).change();
                    // more items
                    if (e.id === '!#more#') {
                        that.Max += cs.MaxResults;
                        that.MaxRequest += cs.MaxResults;
                        that.input.typeahead('changeMax', that.Max);
                        that.input.typeahead('setActive', { id: null, name: '' });
                        that.input.typeahead('lookup', '').focus();

                        return;
                    }
                    that.selectedValue = e.id;
                    that.input.typeahead('setActive', e);
                    that._trigger('rayControlChanged', {}, cs);
                }
            });

        }, // end init-auto-complete

        _loadQueryDataSource: function (query) {
            //console.log("Dropdown load query", this.options.controlState.Id);
            const that = this;
            this._offlineDataSource = [];
            const postData = {
                keywords: query,
                controlId: this.options.controlState.Id,
                pageInstance: this.pageInstanceId,
                maxResults: that.MaxRequest,
                isSortForced: that.IsSortForced
            };
            return utils.callWebAPIp("api/control/getitems", postData);
        },

        _transformDataItems: function (result) {
            const items = [];
            for (let i = 0; i < result.d.length; i++)
                items.push({ id: result.d[i].Value, name: result.d[i].Name });
            return items;
        }, // end transform data items

        _loadSelectedValue: function (cs) {
            if (cs.Value == null)
                return;

            const that = this;
            const pageInstanceId = this.options.bpmsAppForm.dto.formState.PageInstanceId;
            const params = { selectedValue: cs.Value, controlId: cs.Id, pageInstance: pageInstanceId };
            utils.callWebAPI("api/control/getitem", params, function (result) {
                //console.log("load value", result.d, result.d.Value, result.d.Name);
                cs.Value = result.d.Value;
                if (cs.Value !== "" && cs.Name !== "") {
                    that.input.typeahead('setActive', { id: cs.Value, name: result.d.Name });
                    that.input.attr('title', result.d.Name);
                }
                else
                    that.input.typeahead('setActive', { id: null, name: null });
            });
        },

        _loadOfflineDataSource: function () {
            const deferred = $.Deferred();
            const promise = deferred.promise();

            const that = this;
            this._offlineDataSource = [];
            const postData = {
                keywords: "",
                controlId: this.options.controlState.Id,
                pageInstance: this.pageInstanceId,
                maxResults: 0,
                isSortForced: that.IsSortForced
            };

            utils.callWebAPI("api/control/getitems", postData, function (result) {
                that._offlineDataSource = [];
                for (let i = 0; i < result.d.length; i++)
                    if (result.d[i].Name !== "")
                        that._offlineDataSource.push({ id: result.d[i].Value, name: result.d[i].Name.trim() });
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return promise;
        }

    }) // end widget
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayComment', $.ui.rayControl, {
        newComments: [],
        isEditMode: false,
        _create: function () {
            const self = this;
            this.validators = [];
            self.element.find('.btnSendCommentToSubject').click(function () {
                self.element.find('.SendBox').attr('parentId', "00000000-0000-0000-0000-000000000000");
                self.CreateComment();
                const container = self.element;
                container.scrollTop(
                    self.element.find('.SendBox').offset().top - container.offset().top + container.scrollTop()
                );
            });

            this.element.find('.btnSendComment').click(function () {
                let newComment = {};
                $(self.newComments).each(function () {
                    if (this.Id == self.element.find('.btnSendComment').attr("id"))
                        newComment = this;
                });
                newComment.Description = self.element.find('.msgBody').val();
                if (self.element.find('.SendBox').attr('parentId') !== "" &&
                    self.element.find('.SendBox').attr('parentId') != null)
                    newComment.ParentId = self.element.find('.SendBox').attr('parentId');

                if (!newComment.Description || $.trim(newComment.Description).length === 0)
                    utils.messagePD('comment_control.empty', 2);
                else {
                    let params = {
                        comment: newComment,
                        pageInstance: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                        clientId: self.options.controlState.Id,
                        bindingId: self.options.controlState.BindingId,
                        isInEditMode: self.isEditMode
                    };

                    utils.callWebAPIp("api/comment/send", params, "POST").done(
                        function (result) {
                            let index;
                            if (result.d != null) {
                                const t = result.d;

                                if (self.isEditMode === true) {
                                    const messageList = $('<ul/>').addClass("bpmsInfo");
                                    messageList.append($('<li>').append($("<h2>").html(t.Message)));
                                    if (t.Result === 0) {
                                        self.reffresh();
                                        index = self.newComments.indexOf(newComment);
                                        if (index >= 0)
                                            self.newComments.splice(index, 1);
                                    }

                                    //error
                                    if (t.Result === 1) {
                                        utils.modalDialogWithContainer(i18n.$t('error'), messageList,
                                            function () {
                                                self.reffresh();
                                                const index = self.newComments.indexOf(newComment);
                                                if (index >= 0)
                                                    self.newComments.splice(index, 1);
                                            });
                                    }

                                    //warning
                                    if (t.Result === 2) {
                                        utils.modalDialogWithContainer(i18n.$t('Warning'), messageList,
                                            function () {
                                                params = {
                                                    pageInstance: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                    clientId: self.options.controlState.Id,
                                                    stateByte: 2
                                                };

                                                utils.callWebAPIp("api/comment/commit", params, "POST").done(
                                                    function () {
                                                        self.reffresh();
                                                        const index = self.newComments.indexOf(newComment);
                                                        if (index >= 0)
                                                            self.newComments.splice(index, 1);
                                                    });
                                            });
                                    }

                                    //Question
                                    if (t.Result === 4) {
                                        utils.confirm(messageList, {
                                            yes: function () {
                                                params = {
                                                    pageInstance: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                    clientId: self.options.controlState.Id,
                                                    stateByte: 4
                                                };
                                                utils.callWebAPIp("api/comment/commit", params, "POST").done(
                                                    function () {
                                                        self.reffresh();
                                                        const index = self.newComments.indexOf(newComment);
                                                        if (index >= 0)
                                                            self.newComments.splice(index, 1);
                                                    });
                                            },
                                            no: function () {
                                                params = {
                                                    pageInstance: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                    clientId: self.options.controlState.Id,
                                                    comment: newComment
                                                };

                                                //utils.callWebService("WebServices/RayCommentService.asmx", "RevertChanges", params, true).done(
                                                utils.callWebAPIp("api/comment/revert", params, "POST").done(
                                                    function () {
                                                        self.reffresh();
                                                        const index = self.newComments.indexOf(newComment);
                                                        if (index >= 0)
                                                            self.newComments.splice(index, 1);
                                                    });
                                            }
                                        });
                                    }

                                } else {
                                    self.reffresh();
                                    index = self.newComments.indexOf(newComment);
                                    if (index >= 0)
                                        self.newComments.splice(index, 1);
                                }
                            }
                        }).fail(function (e, status, thrownError) {                            
                            utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                        });
                }
            });
        },

        onChange: function () {
            this._trigger('rayControlChanged', {}, this.options.controlState);
        },

        reffresh: function () {
            this.isEditMode = false;
            this.GetComments();
            this.onChange();
            this.element.find('.SendBox').addClass('hide');
            this.element.find('.SendBox').removeClass('show');
            this.element.find('.msgBody').val('');
        },

        GetHistoryDiv: function (commentInfo, id, viewEditDeleteClass) {
            return `
<div class='row CommentFooter'>
  <div>
    <hr style='margin:0'/>
    <span class='writer' id='lblWriter'>${commentInfo}</span>
    <button type='button' id='rpl${id}' class='btn btn-default pull-right btn-sm btnReply' style='margin:3px; padding:3px;' data-toggle='tooltip' title='${i18n.$t("reply")}'>
      <i class='fad fa-comment-alt'></i>
    </button>
    <button type='button' id='edt${id}' class='btn btn-default pull-right btn-sm btnEdit ${viewEditDeleteClass}' style='margin:3px; padding:3px;' data-toggle='tooltip' title='${i18n.$t("edit")}'>
      <i class='fad fa-edit'></i>
    </button>
    <button type='button' id='dlt${id}' class='btn btn-default pull-right btn-sm btnDelete ${viewEditDeleteClass}' style='margin:3px; padding:3px;' data-toggle='tooltip' title='${i18n.$t("delete")}'>
      <i class='fad fa-trash'></i>
    </button>
  </div>
</div>`;
        },

        GetBackButton: function () {
            return `
<button type='button' class='btn btn-info pull-left btn-sm btnBack' style='margin-top:5px'>
  <span>${i18n.$t('comment_control.back')}</span>
</button>`;
        },

        GetMessageSpan: function (body) {
            const text = body.replace(/\n/gmi, '<br/>');
            return `<p class='CommentBody'>${text}</p>`;
        },

        GetLinkedComment: function (parentId, id, allComments) {
            var parentComment = null;
            $(allComments).each(function () {
                if (this.Id === parentId) {
                    parentComment = this;
                    return;
                }
            });

            if (parentComment == null)
                return "";

            const txt = `${parentComment.Description.slice(0, 100)}...`;
            return `<a Parent='${parentId}' id='l${id}' class='ReplyBoxLink'>  <div class='ReplyBox'> <span>${txt}</span></div> </a>`;
        },
        CreateComment: function () {
            const self = this;
            const params = {
                pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                clientId: this.options.controlState.Id,
                bindingId: this.options.controlState.BindingId
            };
            utils.callWebAPIp("api/comment/get", params, "GET").done(
                function (result) {
                    if (result.d != null) {
                        const newComment = result.d;
                        self.AddCommentToSendBox(newComment);
                    }
                }).fail(function (e, status, thrownError) {     
                    utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                });
        },

        CreateCommentBody: function (comment, allComments) {
            const id = comment.Id;

            let row = "<div class='row CommentRow'>";
            let message = "<div class='Message'>";
            const backbtn = this.GetBackButton();
            const span = this.GetMessageSpan(comment.Description);

            if (comment.ParentId != '' &&
                comment.ParentId != 'undefind' &&
                comment.ParentId != null &&
                comment.ParentId != "00000000-0000-0000-0000-000000000000") {
                const linkedComment = this.GetLinkedComment(comment.ParentId, id, allComments);
                message = message + linkedComment;
            }
            message = `${message + span} ${backbtn}</div>`;
            row = `${row + message}</div>`;
            let viewEditDelete = 'hide';
            if (comment.IsAllowEdit == true)
                viewEditDelete = '';
            const row2 = this.GetHistoryDiv(comment.SenderInfo, id, viewEditDelete);
            let body = `<div id='${id}' style='background-color:#eff0f1; margin-top: 5px; ' >`;
            body = `${body + row + row2}</div>`;
            this.element.find('.CommentList').append(body);

        },

        GetComments: function () {
            const self = this;
            const params = {
                pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                serverId: this.options.controlState.Id,
                bindingId: this.options.controlState.BindingId
            };
            let lastItem;
            utils.callWebAPIp("api/comment/getlist", params, "GET").done(
                function (result) {
                    if (result.d != null) {
                        self.element.find('.CommentList').empty();
                        $(result.d).each(function () {
                            self.CreateCommentBody(this, $(result.d));
                        });
                        self.element.find('.ReplyBoxLink').click(function (e) {

                            e.preventDefault();
                            self.element.find('.ReplyClicked').each(function () {
                                $(this).removeClass('ReplyClicked');
                            });
                            const container = self.element;
                            lastItem = $(this).attr('id');
                            const id = $(this).attr('Parent');
                            container.scrollTop(
                                self.element.find(`#${id}`).offset().top -
                                container.offset().top +
                                container.scrollTop() -
                                100
                            );
                            self.element.find('.CommentList').find(`#${id}`).addClass('ReplyClicked');

                        });
                        self.element.find('.btnBack').click(function () {
                            $(this).parents().removeClass('ReplyClicked');
                            const container = self.element;
                            container.scrollTop(
                                container.find(`#${lastItem}`).offset().top -
                                container.offset().top +
                                container.scrollTop() -
                                50
                            );
                            //window.history.go(-1);
                        });
                        self.element.find('.btnReply').click(function () {

                            self.element.find('.SendBox').attr('parentId', $(this).attr('id').slice(3));
                            self.CreateComment();
                            const container = self.element;
                            container.scrollTop(
                                self.element.find('.SendBox').offset().top -
                                container.offset().top +
                                container.scrollTop()
                            );
                        });

                        self.element.find('.btnEdit').click(function () {
                            const commentId = $(this).attr('id').slice(3);
                            const params = {
                                commentId: commentId,
                                clientId: self.options.controlState.Id,
                                pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                bindingId: self.options.controlState.BindingId
                            };

                            //utils.callWebService("WebServices/RayCommentService.asmx", "GetDomainObjectForEdit", params, true).done(
                            utils.callWebAPIp("api/comment/getobject", params, "POST").done(
                                function (result) {
                                    if (result.d != null) {
                                        self.isEditMode = true;
                                        const newComment = result.d;
                                        self.AddCommentToSendBox(newComment);
                                    }
                                }).fail(function (e, status, thrownError) {    
                                    utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                                });
                        });

                        self.element.find('.btnDelete').click(function () {
                            const commentId = $(this).attr('id').slice(3);
                            noty({
                                text: i18n.$t('comment_control.delete_confirm'),
                                type: 'confirm',
                                layout: 'center',
                                modal: true,
                                buttons: [
                                    {
                                        text: i18n.$t('submit'),
                                        onClick: function ($noty) {
                                            $noty.close();
                                            let params = {
                                                id: commentId,
                                                clientId: self.options.controlState.Id,
                                                pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                bindingId: self.options.controlState.BindingId
                                            };
                                            utils.callWebAPIp("api/comment/delete", params, "POST").done(
                                                function (result) {
                                                    const t = result.d;
                                                    const messageList = $('<ul/>').addClass("bpmsInfo");
                                                    messageList.append($('<li>').append($("<h2>").html(t.Message)));
                                                    if (t.Result === 0) {
                                                        self.reffresh();
                                                    }
                                                    //error
                                                    if (t.Result === 1) {
                                                        utils.modalDialogWithContainer(i18n.$t('error'),
                                                            messageList,
                                                            function () {
                                                                self.reffresh();
                                                            });
                                                    }
                                                    //warning
                                                    if (t.Result === 2) {
                                                        utils.modalDialogWithContainer(i18n.$t('Warning'),
                                                            messageList,
                                                            function (resultdialog) {

                                                                params = {
                                                                    pageInstance: self.options.bpmsAppForm.dto
                                                                        .formState
                                                                        .PageInstanceId,
                                                                    clientId: self.options.controlState.Id,
                                                                    stateByte: 2
                                                                };
                                                                utils.callWebAPIp("api/comment/commit", params, "POST").done(
                                                                    function () {
                                                                        self.reffresh();
                                                                    });
                                                            });
                                                    }
                                                    //Question
                                                    if (t.Result === 4) {
                                                        utils.confirm(messageList,
                                                            {
                                                                yes: function () {
                                                                    params = {
                                                                        pageInstance: self.options.bpmsAppForm.dto
                                                                            .formState.PageInstanceId,
                                                                        clientId: self.options.controlState.Id,
                                                                        stateByte: 2
                                                                    };
                                                                    utils.callWebAPIp("api/comment/commit", params, "POST").done(
                                                                        function () {
                                                                            self.reffresh();
                                                                        });
                                                                },
                                                                no: function () {
                                                                    self.reffresh();
                                                                }
                                                            });

                                                    }

                                                }).fail(function (e, status, thrownError) {                                               
                                                    utils.showAjaxError(e,
                                                        status,
                                                        thrownError,
                                                        self.options.controlState.Id,
                                                        false);
                                                });
                                        }
                                    },
                                    {
                                        text: i18n.$t('cancel'),
                                        onClick: function ($noty) {
                                            $noty.close();
                                        }
                                    }
                                ]
                            });
                        }
                        );
                        self._setBehaviour();
                    }
                }).fail(function (e, status, thrownError) {                  
                    utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                });

        },
        AddCommentToSendBox: function (comment) {
            this.element.find('.SendBox').removeClass('hide');
            this.element.find('.SendBox').addClass('show');
            this.element.find('.msgBody').val(comment.Description);
            this.element.find('.btnSendComment').attr("id", comment.Id);
            this.newComments.push(comment);
        },
        _setBehaviour: function () {
            if (!this.options.controlState.EnableNew) {
                this.element.find('.btnSendCommentToSubject').css("display", "None");
                this.element.find('.btnSendCommentToSubject').off();
                this.element.find('.btnReply').css("display", "None");
                this.element.find('.btnReply').off();
            }
            if (!this.options.controlState.EnableEdit) {
                this.element.find('.btnEdit').css("display", "None");
                this.element.find('.btnEdit').off();
            }
            if (!this.options.controlState.EnableDelete) {
                this.element.find('.btnDelete').css("display", "None");
                this.element.find('.btnDelete').off();
            } else
                this.element.find('.btnDelete').removeClass('.hide');

            if (!this.options.controlState.Behavior.Enabled) {
                this.element.find('.btnSendCommentToSubject').prop("disabled", true);
                this.element.find('.btnSendCommentToSubject').off();
                this.element.find('.btnReply').prop("disabled", true);
                this.element.find('.btnReply').off();
                this.element.find('.btnEdit').css("disabled", true);
                this.element.find('.btnEdit').off();
                this.element.find('.btnDelete').css("disabled", true);
                this.element.find('.btnDelete').off();
            } else {
                this.element.find('.btnSendCommentToSubject').on();
                this.element.find('.btnReply').on();
                this.element.find('.btnEdit').on();
                this.element.find('.btnDelete').on();
            }
        },
        _setValue: function () {

            this.GetComments();
        },
        _init: function () {
        },
        //setState: function (cs) {
        //   // this._setValue();
        //},
        getControlValue: function () {
            return null;
        },

        requiredFieldValidator: function () {
            //console.log("Comment validator", this.element.find('.CommentRow').length, this.element.find('.CommentRow'));
            return this.element.find('.CommentRow').length > 0;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayRectangle', $.ui.rayControl, {
        _create: function () {
        },
        _setState: function (cs) {
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayRadioButtonList', $.ui.rayControl, {
        lastValue: null,
        radioGroupEl: null,
        radioGroupDs: null,
        isChanged: false,
        init: true,
        devExpressRadioButton: false,
        _create: function () {
            var behavior = this.options.controlState.Behavior;
            if (behavior && behavior.Items && behavior.Items.length > 0) {
                this.devExpressRadioButton = true;
            }

            const isRightToLeft = payload.currentLocale.IsRightToLeft;
            if (this.devExpressRadioButton) {
                var items = behavior.Items;
                const nbrColumns = behavior.Columns;
                //Max 12 columns
                if (nbrColumns > 12)
                    nbrColumns = 12;
                var isHorizontal = behavior.IsHorizontal;
                var dataSource = Object.keys(items).map(function (x) {
                    var item = items[x];
                    return {
                        textLocal: item.TextLocal,
                        value: item.Value,
                        disabled: !item.Enabled,
                        selected: item.Selected,
                    };
                })

                const that = this;
                var layout = isHorizontal ? "horizontal" : "vertical";
                if (!isHorizontal && nbrColumns > 1) {
                    var nbrItems = items.length;
                    var orderedDataSource = new Array()
                    for (let i = 0; i < items.length; i++) {
                        var newIndex = that.getNewIndexForVerticalList(i, nbrColumns, nbrItems);
                        orderedDataSource.push(dataSource[newIndex]);
                    }
                    dataSource = orderedDataSource;
                    layout = "horizontal";
                }

                this.radioGroupDs = dataSource;
                this.element.children("div:first").remove();
                const el = document.createElement('div');
                el.classList.add('customRadioPanel');
                this.element.append(el);
                const tabIndex = $(this.element).attr("tabindex");
                $(this.element).removeAttr("tabindex");
                this.radioGroupEl = new RadioGroup(this.element.find('.customRadioPanel'), {
                    dataSource: dataSource,
                    valueExpr: 'value',
                    displayExpr: 'text',
                    rtlEnabled: isRightToLeft,
                    onValueChanged(e) {
                        if (e.previousValue != e.value) {
                            that.options.controlState.Value = e.value;
                            //console.log("RadioButtonList changed", that.options.controlState.Id, e.previousValue, e.value);
                            if (e.event)
                                that._trigger('rayControlChanged', {}, that.options.controlState);
                        }
                    },
                    layout: layout,
                    tabIndex: tabIndex,
                    itemTemplate: function (data, index, element) {
                        const item = document.createElement('div');
                        item.classList.add("localizable");
                        $(item).text(data.textLocal)
                        element.append(item);
                    }
                });
                if (dataSource.find(x => x.selected)) {
                    this.radioGroupEl.option('value', dataSource.find(x => x.selected).value);
                    this._trigger('rayControlChanged', {}, this.options.controlState);
                }

                if (nbrColumns > 1) {
                    this.element.find(".dx-collection").addClass(`radiolist-flex-${nbrColumns}-cols`);
                }
            }
            else {
                this.validators = [];
                const tabIndex = $(this.element).attr("tabindex");
                $(this.element).removeAttr("tabindex");

                $(this.element).find(":radio").each(function () {
                    $(this).attr("tabindex", tabIndex);
                });
                this._on(this.element.find('input'), {
                    'click': this._click
                });
            }
        },
        _click: function (args) {
            const target = args.delegateTarget;
            if (this.lastValue != $(target).val())
                this._trigger('rayControlChanged', {}, this.options.controlState);
            this.lastValue = $(target).val();
        },
        _setValue: function (value) {
            if (this.devExpressRadioButton) {
                if (this.init && value == null) {
                    this.init = false;
                } else {
                    if (value == null)
                        value = "";
                    this.radioGroupEl.option('value', value.toString());
                }
            }
            else {
                const self = this;
                //console.log("Radio button set value", this.options.controlState.Id, value);
                if (value == null) {
                    //const selectedRadio = this.element.find(":checked");
                    const newVal = null;//selectedRadio.val();
                    this.element.value = newVal;
                    const changed = this.lastValue != newVal && !(this.lastValue == null || typeof this.lastValue == "undefined" || typeof newVal == "undefined");
                    this.isChanged = changed;
                    this.lastValue = newVal;
                    this.options.controlState.Value = newVal;
                    //if (changed)
                    //    this._trigger('rayControlChanged', {}, this.options.controlState);
                } else
                    this.lastValue = value;

                this.element.find('input').each(function () {
                    if (self.lastValue != null)
                        self.lastValue = $.trim(self.lastValue);

                    let controlValue = $.trim($(this).attr('value'));
                    if (self.lastValue === "true" || self.lastValue === "false") {
                        if ($(this).attr('value') == false)
                            controlValue = "false";
                        else if ($(this).attr('value') == true)
                            controlValue = "true";
                    }
                    //console.log("Radio button set value 2", self.options.controlState.Id, controlValue, self.lastValue );
                    if (controlValue == self.lastValue || (self.lastValue != null && controlValue == self.lastValue.toString())) {
                        $(this).attr('checked', 'checked');
                        $(this).parent().attr('selecteditem', 'true');
                    } else {
                        $(this).removeAttr('checked');
                        $(this).parent().attr('selecteditem', 'false');
                    }
                    //if ($(this).attr('value') == self.lastValue || (self.lastValue != null && $(this).attr('value') == self.lastValue.toString())) {
                    //    $(this).attr('checked', 'checked');
                    //    $(this).parent().attr('selecteditem', 'true');
                    //}
                    //else {
                    //    $(this).removeAttr('checked');
                    //    $(this).parent().attr('selecteditem', 'false');
                    //}
                });
            }
        },

        _setEnable: function (enable) {
            if (this.devExpressRadioButton) {
                this.radioGroupEl.option('disabled', !enable);
            } else {
                if (enable)
                    this.element.find('input').attr('disabled', null);
                else
                    this.element.find('input').attr('disabled', 'disabled');
            }
            this._setEnableDefault(this.element, enable);

        },
        _setVisible: function (visible) {
            this._super(visible);
        },
        getValue: function () {
            if (this.devExpressRadioButton) {
                return this.radioGroupEl.option('value');
            } else {
                const element = this.element.find(":checked");
                return element.length === 0 ? null : $(element).val();
            }
        },
        requiredFieldValidator: function () {
            if (this.devExpressRadioButton) {
                return !!this.radioGroupEl.option('value');
            } else {
                const element = this.element.find(":checked");
                return element.length !== 0;
            }
        },
        getNewIndexForVerticalList: function (index, nbrColumns, nbrElements) {
            let r = Math.floor(index / nbrColumns);
            let c = index - (Math.floor(index / nbrColumns) * nbrColumns);
            let x = nbrElements - (Math.floor(nbrElements / nbrColumns) * nbrColumns);
            let b = Math.ceil(nbrElements / nbrColumns);
            if(c < x || x == 0)
                return c * b + r;
            else
                return x * b + ((c - x) * (b - 1)) + r;
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayCheckBox', $.ui.rayControl, {
        checkboxEl: null,
        _create: function () {

            const isRightToLeft = payload.currentLocale.IsRightToLeft;
            //Get caption from label element and remove element after
            var labelEl = this.element.find('label');
            var caption = labelEl.text();
            var captionLocalHtml = labelEl.attr(`data-caption-${payload.currentLocale.Language}`);
            var captionLocalText = $(document.createElement("div")).html(captionLocalHtml).text(); //Caption is html, transform to text
            var labelCss = labelEl[0].style.cssText;
            if (captionLocalText) { caption = captionLocalText; }
            labelEl.remove();

            //If the application is not rebuilt and having the old checkbox system
            if (this.element.find('input').length !== 0)
            {
                this.element.find('input').remove();
                const el = document.createElement('div');
                el.classList.add('customCheckbox');
                this.element.append(el);
            }

            //Deal with tab index
            const tabIndex = $(this.element).attr("tabindex");
            $(this.element).removeAttr("tabindex");
            //Initialize checkbox
            const that = this;
            this.checkboxEl = new CheckBox(this.element.find('.customCheckbox'), {
                text: caption,
                value: false,
                rtlEnabled: isRightToLeft,
                onValueChanged: function (data) {
                    //if (data.event)
                    //    console.log("checkbox changed by user", that.options.controlState.Id);
                    //else
                    //    console.log("checkbox changed programatically", that.options.controlState.Id);
                    if (data.previousValue != data.value) {
                        that.options.controlState.Value = data.value;
                        if (data.event)
                            that._trigger('rayControlChanged', {}, that.options.controlState);
                    }
                },
                tabIndex: tabIndex
            });
            //Apply custom checkbox formatting
            var newLabelEl = this.element.find('.dx-checkbox-text')[0];
            if (newLabelEl)
                newLabelEl.style.cssText += labelCss;

            //if (this.options.controlState)
            //    this.lastValue = this.options.controlState.Value;
        },
        _setValue: function (value) {
            //If value is null, checkbox option will be indeterminate (with square inside checkbox), we dont want it

   
            if (value == null) {
                value = ""; //to prevent show indeterminate case
            }


            if (value !== null) {
                this.checkboxEl.option('value', value);
            }
        },
        _setEnable: function (enable) {
            this.checkboxEl.option('disabled', !enable);
            this._setEnableDefault(this.element, enable);
        },
        _setVisible: function (visible) {
            this._super(visible);
            this.checkboxEl.option('visible', visible);
        },
        getValue: function () {
            return this.checkboxEl.option('value');
        },
        getCaptionElement: function () {
            return this.element.find('.dx-checkbox-text:first');
        },
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayImage', $.ui.rayControl, {
        _setValue: function (value) {
            if (value) {
                const t = $(this.element).prop("tagName");
                if (t !== null) {
                    if (t.toLowerCase() === "div") {
                        const img = this.element.find("img:first");
                        img.attr('src', `api/file/getappfile?imageType=${this.options.controlState.ControlType}&pageInstanceId=${this.options.bpmsAppForm.dto.formState.PageInstanceId}&serverId=${this.options.controlState.Id}&value=${value}`);
                    } else {
                        this.element.attr('src', `api/file/getappfile?imageType=${this.options.controlState.ControlType}&pageInstanceId=${this.options.bpmsAppForm.dto.formState.PageInstanceId}&serverId=${this.options.controlState.Id}&value=${value}`);
                    }
                }
            }
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayUserSignature', $.ui.rayImage, {
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
                return;
            }
            this._super();
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayUserPicture', $.ui.rayImage, {
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
                return;
            }
            this._super();
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayDynamicImage', $.ui.rayImage, {
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
                return;
            }
            this._super();
        },
    }
    );
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayButton', $.ui.rayControl, {
        originColor: null,
        _create: function () {
            const self = this;

            this.element.removeAttr("onclick");
            this.originColor = this.element.css('color');

            this.element.attr("title", jQuery.trim(this.element.text()));

            this._on(this.element, {
                'click': function () {
                    self._trigger('rayControlChanged', {}, this.options.controlState);
                    return false;
                },
                'keydown': function (e) {
                    if (e.keyCode === 0 || e.keyCode === 32) {
                        self._trigger('rayControlChanged', {}, this.options.controlState);
                        return false;
                    }
                }
            });
        },
        setState: function (cs) {
            if (typeof cs === "undefined" || cs === null) return;
            this._setValue(cs.Value);
            this._setEnable(cs.Behavior.Enabled);
            this._setTabIndex(cs.Behavior.Enabled);
            this._setVisible(cs.Behavior.Visible);
            this.setBtnCaption(cs);
            this.setColor(cs);
        },
        setBtnCaption: function (cs) {
            if (cs.Caption === null) return;
            this.element.text(cs.Caption);
        },
        setColor: function (cs) {
            if (!cs.Color) return;
            if ((cs.Color).toLowerCase() !== "default" && (cs.Color).toLowerCase() !== "primary" && (cs.Color).toLowerCase() !== "success" &&
                (cs.Color).toLowerCase() !== "info" && (cs.Color).toLowerCase() !== "warning" && (cs.Color).toLowerCase() !== "danger" && (cs.Color).toLowerCase() !== "link") {
                return;
            }
            this.element.removeClass("btn-default");
            this.element.removeClass("btn-primary");
            this.element.removeClass("btn-success");
            this.element.removeClass("btn-info");
            this.element.removeClass("btn-warning");
            this.element.removeClass("btn-danger");
            this.element.removeClass("btn-link");

            this.element.addClass(`btn-${(cs.Color).toLowerCase()}`);
        },
        _setEnable: function (enable) {
            //this._super(enable);
            const inp = typeof this.input != "undefined" ? this.input : this.element;

            if (enable) {
                inp.removeAttr('readonly');
                inp.removeAttr('disabled');
            } else {
                inp.attr('readonly', 'readonly');
                inp.attr('disabled', 'disabled');
            }
            this._setEnableDefault(this.element, enable);
        },
        _setValue: function (value) {
            if ($(this.element).prop("tagName") === "INPUT")
                $(this.element).attr("value", $(this.element).attr("Text"));
            else
                $(this.element).text($(this.element).attr("Text"));

            if (jQuery.trim(value) !== "" && value !== null && typeof value !== "undefined")
                this.element.attr("title", value);
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.raySearchPanel', $.ui.rayControl, {
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
                return;
            }
            const self = this;
            this.element.find('.setFilter').unbind('click').bind('click', function () {
                self.options.bpmsAppForm.setFilter(self.options.controlState.Id);
            });
            this.element.find('.resetFilter').width('');

            this.element.find('.resetFilter').unbind('click').bind('click', function () {
                self.options.bpmsAppForm.resetFilter(self.options.controlState.Id);
            });

            this.element.find('input[name*="RayDatePicker"]').on('change', function () {
                $(this).focus();
            });

            this.element.find('input').on('keypress', function (e) {
                if (e.which === 13) {
                    $(this).trigger('blur');
                    self.element.find('.setFilter').trigger('click');
                }
            });
        },
        getValue: function () {
            return this.options.controlState.Value;
        }
    });
})(jQuery);

//Payment
(function ($, undefined) {
    $.widget('ui.rayPayment', $.ui.rayControl, {


        _setEnable: function (enable) {
            this._SetEnable(this.element.find('.PAccount'), enable);
            this._SetEnable(this.element.find('.PButton'), enable);
            this._SetEnable(this.element.find('.PStat'), enable);
            this._SetEnable(this.element.find('.PPrice'), enable);
            this._SetEnable(this.element.find('.PABox'), enable);
            this._SetEnable(this.element, enable);
        },

        _setVisible: function (visible) {
            this._SetVisible(this.element.find('.PAccount'), visible);
            this._SetVisible(this.element.find('.PButton'), visible);
            this._SetVisible(this.element.find('.PStat'), visible);
            this._SetVisible(this.element.find('.PPrice'), visible);
            this._SetVisible(this.element.find('.PABox'), visible);
            this._SetVisible(this.element, visible);
        },

        _SetEnable: function (obj, enabled) {
            if (enabled != null) {
                let attrName = 'disabled';
                if (obj.hasClass('RayTextBox'))
                    attrName = 'readonly';
                if (enabled) obj.removeAttr(attrName);
                else obj.attr(attrName, attrName);
            }
            this._setEnableDefault(this.element, enable);
        },

        _SetVisible: function (obj, visible) {
            if (visible != null)
                obj.css('visibility', visible ? 'visible' : 'hidden');
        },

        _SetEnableVisible: function (obj, enable, visible) {
            this._SetEnable(obj, enable);
            this._SetVisible(obj, visible);
        },

        _create: function () {
        },

        _init: function () {
        },

        getValue: function () {
            const cs = this.options.controlState;
            if (!(cs.Behavior.Enabled && cs.Behavior.Visible)) {
                return null;
            }
            return cs.Value; //self.Element.data('status');
        },

        requiredFieldValidator: function () {
            const cs = this.options.controlState;
            return cs && cs.Value.Status == 1;
        },

        setState: function (cs) {
            const self = this;
            const op = this.options;
            const controlState = cs;
            const controlId = `${controlState.Id}payment`;
            const drpAccount = self.element.find('.PAccount');
            const btnPay = self.element.find('.PButton');
            const lblState = self.element.find('.PStat');
            const lblPrice = self.element.find('.PPrice');
            const accountBox = self.element.find('.PABox');
            let currentPaymentWindow;

            btnPay.unbind('click').bind('click', function () {
                if (drpAccount.val()) {
                    if (currentPaymentWindow && !currentPaymentWindow.closed)
                        utils.modalDialog('', i18n.$t("ray_payment.messages.close_pay_win"), 300, 150);
                    else {
                        op.bpmsAppForm.saveFormObject().done(function () {
                            currentPaymentWindow = window.open(`./StaticViews/Payment.aspx?controlId=${controlState.Id}&pageInsId=${op.bpmsAppForm.dto.formState.PageInstanceId}&propertyName=${controlState.PropertyName}&accountId=${drpAccount.val()}`, "_blank", "menubar=0,status=0,scrollbars=1,location=1", true);
                        });
                    }
                }
            });

            this._setEnable(controlState.Behavior.Enabled);
            this._setTabIndex(controlState.Behavior.Enabled);
            this._setVisible(controlState.Behavior.Visible);

            if (controlState.Behavior.Visible === true) {

                this._SetEnableVisible(btnPay, false, true);

                if (controlState.Value) {

                    this._SetEnableVisible(accountBox, true, true);
                    this._SetEnableVisible(drpAccount, false);
                    let statusStr;
                    switch (controlState.Value.Status) {
                        case 0:
                            statusStr = i18n.$t("ray_payment.not_payed_status");
                            if (controlState.Behavior.Enabled) {
                                this._SetEnableVisible(drpAccount, true, true);
                                this._SetEnableVisible(btnPay, true, true);
                            }
                            break;
                        case 1:
                            statusStr = i18n.$t("ray_payment.wait_status");
                            break;
                        case 2:
                            statusStr = i18n.$t("ray_payment.payed_status");
                            break;
                        default:
                            statusStr = i18n.$t("ray_payment.failed_status");
                    }
                    if (controlState.Value.Status != 0
                        && controlState.Value.Status != 1 && controlState.Value.Status != undefined) {

                        self._trigger('rayControlChanged', {}, self.options.controlState);
                    }


                    //    //$('#appform').BPMSForm('updateForm', obj.attr("serverId"));

                    //self.element.data('status', controlState.Value.Status);
                    lblPrice.text(controlState.PriceStr + i18n.$t("ray_payment.currency"));
                    lblState.text(statusStr);
                    cs.Value.Status = controlState.Value.Status;

                    if (controlState.PriceStr == '0') {
                        this._setEnable(false);
                    }

                } else
                    this._setEnable(false);
            }
        }
    });
})(jQuery);

// RayChart
(function ($, undefined) {
    $.widget('ui.rayChart', $.ui.rayControl, {
        nestedEl: null,
        intervalId: null,
        chartId: null,
        _DrawChart: function (_sendChart) {
            if (_sendChart.options.isPreview) {
                $(_sendChart.element).css('border', 'black solid 1px');
                return;
            }
            const self = _sendChart;
            const op = _sendChart.options;
            const cs = _sendChart.options.controlState;

            if (self.options.controlState.ControlBehaviorId == "00000000-0000-0000-0000-000000000000") {
                self.element.css('border', 'red solid 1px');
                self.element.html(i18n.$t('messages.control_behavior_not_exists'));
                return;
            }

            const at = 250;
            //let chartId = _sendChart.options.controlState.Value;
            if (this.chartId == null)
                this.chartId = _sendChart.options.controlState.Value;
            const alt = i18n.$t('messages.loading');
            const msg = i18n.$t('chart_control.updating_chart');
            self.element.html(`<img id="chartLoadingAnimation" src="dist/loading.gif" alt=${alt} Style="position:absolute;top:0; right:0;" display="none"/>
<span Style="position:absolute;top:10px;right:30px; background-color:white;border-color:black;border-width: 2px;">${msg}</span>${self.element.innerHTML}`);
            const parameters = utils.getParameterByName("parameters");
            utils.callWebAPIp("api/chart/draw", {
                //utils.callWebService('webservices/ChartService.asmx', 'DrawChart', {
                chartId: this.chartId,
                parameters: parameters,
                pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                controlBehaviorId: self.options.controlState.ControlBehaviorId,
                clientId: self.options.controlState.Id
            }, "POST").done(
                function (result) {
                    self.element.css('border', 'red solid 0px');
                    self.element.fadeOut(at, function () {
                        self.element.html(result.d);
                        self.element.fadeIn(at);
                        if (self.options.controlState.HasChild) {
                            self.nestedEl = $('<div id="modalDialog" />');
                            $.each(self.element[0].childNodes[3].areas,
                                function (i, val) {
                                    $(val).unbind('Click').click(function () {
                                        self.setChartParamSession(self.options.controlState.Id, this.attributes["params"].value);
                                        const params = {
                                            nested: {
                                                isNestedPage: true,
                                                appName: cs.AppName,
                                                pageInsId: op.bpmsAppForm.dto.formState.PageInstanceId,
                                                Id: cs.Id,
                                                objstate: 'ChartView',
                                                Title: this.attributes["params"].value
                                            }
                                        };
                                        params.parentBpmsAppForm = op.bpmsAppForm;
                                        params.nested.invisibleSaveButton = true;
                                        params.nested.cancelbuttonTitle = i18n.$t('return');
                                        self.nestedEl.bpmsAppForm(params);
                                    });

                                    const newVal = $(val);
                                });
                        }
                        self.toolbarChart();

                    });
                }).fail(
                    function (a) {
                        self.element.css('border', 'red solid 1px');
                        self.element.html(`${i18n.$t('chart_control.error_in_Get_Data') + a.responseJSON.Message}<label style='visibility: hidden'>${a.responseJSON.StackTrace}</label>`);
                    });
        },
        _setValue: function () {
            const self = this;
            if (self.options.controlState.DoInit != null && !self.options.controlState.DoInit)
                return;
            if (self.options.controlState.Behavior.Visible)
                this._DrawChart(self);

        },
        _create: function () {
            const self = this;
            const op = this.options;
            const cs = this.options.controlState;
            if (cs.ChartInterval) {
                const intervalTime = cs.IntervalTime;
                setInterval(() => {
                    cs.DoInit = true;
                    self._SetRealTimeChart(self);
                }, intervalTime);
            }
            let isFixed = false;
            setInterval(function () {
                if (isFixed == false) {
                    if ($(self.element).is(':visible')) {
                        const img = $('.responsive-map-img');
                        if (img != null && img != 'undefined')
                            $('.responsive-map-img').rwdImageMaps();
                        isFixed = true;
                    }
                } else {
                    if ($(self.element).is(':visible') == false)
                        isFixed = false;
                }

            }, 1000);
        },
        _SetRealTimeChart: function (_sendChart) {
            if (_sendChart.options.isPreview) {
                $(_sendChart.element).css('border', 'black solid 1px');
                return;
            }
            const self = _sendChart;
            const op = _sendChart.options;
            const cs = _sendChart.options.controlState;

            if (self.options.controlState.ControlBehaviorId == "00000000-0000-0000-0000-000000000000") {
                self.element.css('border', 'red solid 1px');
                self.element.html(i18n.$t('messages.control_behavior_not_exists'));
                return;
            }

            const at = 250;
            //let chartId = _sendChart.options.controlState.Value;
            if (this.chartId == null)
                this.chartId = _sendChart.options.controlState.Value;
            const parameters = utils.getParameterByName("parameters");

            utils.callWebAPIp("api/chart/draw", {
                //utils.callWebService('webservices/ChartService.asmx', 'DrawChart', {
                chartId: this.chartId,
                parameters: parameters,
                pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                controlBehaviorId: self.options.controlState.ControlBehaviorId,
                clientId: self.options.controlState.Id
            }, "POST").done(
                function (result) {
                    self.element.css('border', 'red solid 0px');

                    const newChart = result.d;
                    const newSrc = $(newChart).filter('img').attr("src");
                    const newImage = new Image();
                    newImage.src = newSrc;
                    self.element.find('img').attr("src", newSrc);
                    if (self.options.controlState.HasChild) {
                        self.nestedEl = $('<div id="modalDialog" />');
                        $.each(self.element[0].childNodes[3].areas,
                            function (i, val) {
                                $(val).unbind('Click').click(function () {
                                    self.setChartParamSession(self.options.controlState.Id, this.attributes["params"].value);
                                    const params = {
                                        nested: {
                                            isNestedPage: true,
                                            appName: cs.AppName,
                                            pageInsId: op.bpmsAppForm.dto.formState.PageInstanceId,
                                            Id: cs.Id,
                                            objstate: 'ChartView',
                                            Title: this.attributes["params"].value
                                        }
                                    };
                                    params.parentBpmsAppForm = op.bpmsAppForm;
                                    params.nested.cancelbuttonTitle = i18n.$t('return');
                                    self.nestedEl.bpmsAppForm(params);
                                });

                                const newVal = $(val);
                            });
                    }
                    self.toolbarChart();


                }).fail(
                    function (a) {
                        self.element.css('border', 'red solid 1px');
                        self.element.html(`${i18n.$t('chart_control.error_in_Get_Data') + a.responseJSON.Message}<label style='visibility: hidden'>${a.responseJSON.StackTrace}</label>`);
                    });
        },
        onClick: function () {

        },
        setChartParamSession: function (clientId, parameters) {
            utils.callWebAPIp("api/chart/setparams", {
                //utils.callWebService('webservices/ChartService.asmx', 'setParamSession', {
                parameters: parameters,
                pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                clientId: clientId,
                bindingId: this.options.controlState.BindingId
            }, "POST").done().fail();
        },
        toolbarChart: function () {
            $(".btnChartToolbar").hover(
                function () {
                    $(this).css("opacity", ".9");
                },
                function () {
                    $(this).css("opacity", ".5");
                }
            );
            $(".Chart").hover(function () {

                $(this).find(".ChartToolbar").show(200);
            }, function () {
                $(this).find(".ChartToolbar").hide(200);
            });

        },
        _destroy: function () {
            clearInterval(this.intervalId);
        }
    });
})(jQuery);

// RayGauge
(function ($, undefined) {
    $.widget('ui.rayGauge', $.ui.rayControl, {

        _DrawChart: function (thisObj) {
            const self = thisObj;
            const op = thisObj.options;
            const cs = thisObj.options.controlState;
            const at = 250;
            if (this.chartId == null)
                this.chartId = self.options.controlState.Value;
            const alt = i18n.$t('loading');
            const msg = i18n.$t('chart_control.updating_chart');
            self.element.html(`<img id="chartLoadingAnimation" src="dist/loading.gif" alt=${alt} Style="position:absolute;top:0; right:0;" display="none" />
<span Style="position:absolute;top:10px;right:30px; background-color:white;border-color:black;border-width: 2px;">${msg}</span>${self.element.innerHTML}`);
            const parameters = utils.getParameterByName("parameters");
            utils.callWebService('webservices/GaugeService.asmx', 'DrawGauge', {
                controlId: this.chartId, pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                controlBehaviorId: self.options.controlState.ControlBehaviorId,
                clientId: self.options.controlState.Id
            }, true).done(
                function (result) {
                    self.element.fadeOut(at, function () {
                        self.element.html(result.d);
                        self.element.fadeIn(at);
                        if (self.options.controlState.HasChild) {
                            self.nestedEl = $('<div id="modalDialog" />');
                            if (self.element[0].childNodes[3]) {
                                $.each(self.element[0].childNodes[3].areas,
                                    function (i, val) {
                                        $(val).unbind('Click').click(function () {
                                            self.setChartParamSession(self.options.controlState.Id, '');
                                            const params = {
                                                nested: {
                                                    isNestedPage: true,
                                                    appName: self.options.controlState.AppName,
                                                    pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                    Id: self.options.controlState.Id,
                                                    objstate: 'ChartView'
                                                }
                                            };
                                            params.parentBpmsAppForm = op.bpmsAppForm;
                                            params.nested.invisibleSaveButton = true;
                                            params.nested.cancelbuttonTitle = i18n.$t('return');
                                            self.nestedEl.bpmsAppForm(params);
                                        })
                                        const newVal = $(val);
                                    });
                            }
                        }
                        $(".btnChartToolbar").hover(
                            function () {
                                $(this).css("opacity", "1");
                            },
                            function () {
                                $(this).css("opacity", ".7");
                            }
                        );
                    });
                }).fail(
                    function (a) {
                        self.element.css('border', 'red solid 1px');
                        self.element.html(i18n.$t('chart_control.error_in_Get_Data') + a.responseText);
                    });


        },
        _setValue: function () {
            const self = this;
            if (self.options.controlState.DoInit != null && !self.options.controlState.DoInit)
                return;
            this._DrawChart(self);
        },

        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
            }
        },
        setChartParamSession: function (clientId, parameters) {
            //utils.callWebService('webservices/ChartService.asmx', 'setParamSession', {
            utils.callWebAPIp("api/chart/setparams", {
                parameters: parameters,
                pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                clientId: clientId,
                bindingId: this.options.controlState.BindingId
            }, "POST").done().fail();
        }
    });
})(jQuery);

// RayMap
(function ($, undefined) {
    $.widget('ui.rayMap', $.ui.rayControl, {
        _DrawMap: function (objControl) {
            const self = objControl;
            let jsonData;
            const chartId = self.options.controlState.Value;
            const divChart = document.getElementById(`divMap${self.options.controlState.Id}`);
            const alt = i18n.$t('loading');
            const msg = i18n.$t('chart_control.updating_map');
            self.element.html(`<img id="chartLoadingAnimation" src="dist/loading.gif" alt=${alt} Style="position:absolute;top:0; right:0;" display="none" /><span Style="position:absolute;top:10px;right:30px; background-color:white;border-color:black;border-width: 2px;">${msg}</span>${self.element.innerHTML}`);
            utils.callWebService('webservices/MapService.asmx', 'GetData', {
                mapId: chartId, pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                controlBehaviorId: self.options.controlState.ControlBehaviorId,
                clientId: self.options.controlState.Id
            }, true).done(
                function (result) {

                    jsonData = result.d;
                    const markers = jsonData.MarkerArray;
                    const values1 = jsonData.Y1;
                    const values2 = jsonData.Y2;
                    const values3 = {};
                    for (let i = 0; i < jsonData.Y3.length; i++) {
                        values3[jsonData.Y3[i].name] = jsonData.Y3[i].index;
                    }

                    if (markers[0] == null) {
                        self.element.empty();
                        const map = new jvm.WorldMap({
                            container: self.element,
                            map: jsonData.Map.MapType,
                            backgroundColor: jsonData.Map.BgColor,
                            regionStyle: {
                                initial: {
                                    fill: jsonData.Map.CountryColor
                                }
                            },
                            series: {
                                regions: [{
                                    scale: [jsonData.Map.StartColor, jsonData.Map.EndColor],
                                    attribute: 'fill',
                                    normalizeFunction: 'linear',
                                    values: values3
                                }]
                            },
                            regionsSelectable: true,

                            onMarkerLabelShow: function (event, label, code) {
                                const id = label.html();
                                const text = jsonData.Map.MarkerLable.replace("#BubbleColoring", values2[code]).replace("#BubbleSize", values1[code]).replace("#Code", id).replace("#Region", map.getRegionName(id));
                                label.html(text);
                                label.css('left', -200);
                            },

                            onMarkerSelected: function (event, index, isSelected, selectedMarkers) {
                                if (window.localStorage) {
                                    window.localStorage.setItem(
                                        'jvectormap-selected-markers',
                                        JSON.stringify(selectedMarkers)
                                    );
                                }
                            },

                            onRegionLabelShow: function (event, label, code) {
                                if (values3[code] == null) {
                                    label.html(`${map.getRegionName(code)} ,${code}`);
                                } else {
                                    const text = jsonData.Map.RegionLable.replace("#MapColoring", values3[code]).replace("#Region", map.getRegionName(code)).replace("#Code", code);
                                    label.html(text);
                                }
                            },

                            onRegionClick: function (event, code) {
                                if (self.options.controlState.HasChild) {
                                    if (self.options.controlState.Behavior.Enabled) {
                                        if (self.options.controlState.EnableView) {
                                            self.viewTargetFormObjectForMap(self.options.controlState.Id, self.options.bpmsAppForm.dto.formState.PageInstanceId, { "code": code });
                                            const params = {
                                                nested: {
                                                    isNestedPage: true,
                                                    appName: self.options.controlState.AppName,
                                                    pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                    Id: self.options.controlState.Id,
                                                    objstate: 'ChartView'
                                                }
                                            };
                                            params.parentBpmsAppForm = self.options.bpmsAppForm;
                                            self.nestedEl = $('<div id="modalDialog" />');
                                            params.nested.invisibleSaveButton = true;
                                            params.nested.cancelbuttonTitle = i18n.$t('return');
                                            self.nestedEl.bpmsAppForm(params);
                                            //window.opendialog_parent('NestedPage.aspx', map.getRegionName(code), self.options.controlState.TargetFormWidth, controlState.TargetFormHeight, controlState.StateParameters + "&clientId=" + controlState.id);
                                        }
                                    }

                                }
                            }
                        });
                        let isFixed = false;
                        setInterval(function () {
                            if ($(self.element).is(':visible')) {
                                if (isFixed == false) {
                                    map.onResize();
                                    window.temp = map;
                                    isFixed = true;
                                }
                            } else {
                                isFixed = false;
                            }
                        }, 300);
                        //setTimeout(function () {
                        //    map.onResize();
                        //}, 300);
                        //console.log(map);

                    } else {
                        self.element.empty();
                        const map = new jvm.WorldMap({
                            container: self.element,
                            map: jsonData.Map.MapType,
                            markers: markers,
                            backgroundColor: jsonData.Map.BgColor,
                            series: {
                                markers: [{
                                    attribute: 'fill',
                                    scale: [jsonData.Map.StartBubbleColor, jsonData.Map.EndBubbleColor],
                                    normalizeFunction: 'polynomial',
                                    values: values2
                                }, {
                                    attribute: 'r',
                                    scale: [jsonData.Map.StartSize, jsonData.Map.EndSize],
                                    normalizeFunction: 'polynomial',
                                    values: values1
                                }],
                                regions: [{
                                    scale: [jsonData.Map.StartColor, jsonData.Map.EndColor],
                                    attribute: 'fill',
                                    normalizeFunction: 'polynomial',
                                    values: values3
                                }]
                            },
                            regionsSelectable: true,
                            markersSelectable: true,
                            markersSelectableOne: true,

                            onMarkerLabelShow: function (event, label, code) {
                                const id = label.html();
                                const text = jsonData.Map.MarkerLable.replace("#BubbleColoring", values2[code]).replace("#BubbleSize", values1[code]).replace("#Code", id).replace("#Region", map.getRegionName(id));
                                label.html(text);
                                label.css('left', -200);
                            },

                            onMarkerSelected: function (event, index, isSelected, selectedMarkers) {
                                if (window.localStorage) {
                                    window.localStorage.setItem(
                                        'jvectormap-selected-markers',
                                        JSON.stringify(selectedMarkers)
                                    );
                                }
                            },

                            onRegionLabelShow: function (event, label, code) {
                                if (values3[code] == null) {
                                    label.html(`${map.getRegionName(code)} ,${code}`);
                                } else {
                                    const text = jsonData.Map.RegionLable.replace("#MapColoring", values3[code]).replace("#Region", map.getRegionName(code)).replace("#Code", code);
                                    label.html(text);
                                }
                            },

                            onRegionClick: function (event, code) {
                                if (self.options.controlState.Behavior.Enabled) {
                                    if (self.options.controlState.EnableView) {
                                        self.viewTargetFormObjectForMap(self.options.controlState.Id, self.options.bpmsAppForm.dto.formState.PageInstanceId, { "code": code });
                                        const params = {
                                            nested: {
                                                isNestedPage: true,
                                                appName: self.options.controlState.AppName,
                                                pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                                Id: self.options.controlState.Id,
                                                objstate: 'ChartView'
                                            }
                                        };
                                        params.parentBpmsAppForm = self.options.bpmsAppForm;
                                        self.nestedEl = $('<div id="modalDialog" />');
                                        params.nested.invisibleSaveButton = true;
                                        params.nested.cancelbuttonTitle = i18n.$t('return');
                                        self.nestedEl.bpmsAppForm(params);
                                        //window.opendialog_parent('NestedPage.aspx', map.getRegionName(code), self.options.controlState.TargetFormWidth, controlState.TargetFormHeight, controlState.StateParameters + "&clientId=" + controlState.id);
                                    }
                                }
                            }

                        });
                        let isFixed = false;
                        setInterval(function () {
                            if ($(self.element).is(':visible')) {
                                if (isFixed == false) {
                                    map.onResize();
                                    isFixed = true;
                                }
                            } else {
                                isFixed = false;
                            }
                        }, 300);
                        //console.log(map);
                        //setTimeout(function () {
                        //    map.onResize();
                        //}, 300);
                    }
                }).fail(
                    function (a) {
                        self.element.css('border', 'red solid 1px');
                        self.element.html(i18n.$t('chart_control.error_in_get_map_data') + a.responseJSON.Message);
                    });
        },
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
                return;
            }
            const self = this;
            if (self.options.controlState.DoInit != null && !self.options.controlState.DoInit)
                return;
            if (self.options.controlState.ControlBehaviorId == "00000000-0000-0000-0000-000000000000") {
                self.element.css('border', 'red solid 1px');
                self.element.html(i18n.$t('messages.control_behavior_not_exists'));
                return;
            }
            //animation time
            const chartId = self.options.controlState.Value;
            this._DrawMap(self);
            self.element.before(`
<div id="Toolbar${chartId}" class="ChartToolbar" title='${i18n.$t('export')}' style="display:none; right:${self.element.css("right")}; top:${self.element.css("top")}; width:35px;Height:25px; position:absolute; z-index: 3;">
<div id="BtnExport" type="button" class="btnChartToolbar" style="background-repeat: no-repeat;background-position: center center;width: 25px;height:25px;background-color: #333333;opacity:.5;background-image: url('dist/Export.png');" onClick="vm.Export.activate({ pageInstanceId:'${self.options.bpmsAppForm.dto.formState.PageInstanceId}', clientId: '${chartId}' , table: null });">
 </div></div>`
            );
            $(".btnChartToolbar").hover(
                function () {
                    $(self).css("opacity", ".9");
                },
                function () {
                    $(self).css("opacity", ".5");
                }
            );
        },
        _setValue: function () {
            const self = this;
            if (self.options.controlState.DoInit != null && !self.options.controlState.DoInit)
                return;
            this._DrawMap(self);
        },
        viewTargetFormObjectForMap: function (clientId, pageInsId, row) {
            const params = {
                parameters: row,
                pageInstanceId: pageInsId,
                clientId: clientId,
                bindingId: this.options.controlState.BindingId
            };
            const requestHeaders = [];
            requestHeaders.push({ Key: "pageInstanceId", Value: pageInsId });
            utils.callWebService("WebServices/MapService.asmx", "setParamSession", params, true).done().fail(function (xmlHttpRequest) {
                console.error(xmlHttpRequest.responseText);
            });
        }
    });
})(jQuery);


// Accordion
(function ($, undefined) {
    $.widget('ui.accordion', $.ui.rayControl, {
        accordionEl: null,
        isChanged: false,
        init: true,
        initForChildrenNeeded: true,

        _create: function () {
            const self = this;
            var selectedIndex = -1;
            var selectedItems = [];
            var behavior = this.options.controlState.Behavior;
            var items = behavior.Sections;

            var itemInfo = Object.keys(items).map(function (x, i) {
                var item = items[x];

                var element = self.element.find('li a').eq(i);

                var titleStyles = '';
                var colorStyle = '';
                var backgroundColor = '';
                if (item.Bold) { titleStyles += 'font-weight: bold;'; }
                if (item.Italic) { titleStyles += 'font-style: italic;'; }
                if (item.Underline) { titleStyles += 'text-decoration: underline;'; }
                if (item.Color && item.Color.length > 0) { colorStyle = `color: ${item.Color};`; titleStyles += colorStyle; }
                if (item.FontFamily && item.FontFamily.length > 0) { titleStyles += `font-family: ${item.FontFamily};`; }
                if (item.FontSize && item.FontSize != 0) { titleStyles += `font-size: ${item.FontSize}px;`; }
                if (item.IsThemeBackgroundColor)
                    backgroundColor = `background-color: var(--main-bg-color-l25o25) !important;`;
                else if (item.BackgroundColor && item.BackgroundColor.length > 0)
                    backgroundColor = `background-color: ${item.BackgroundColor} !important;`;
               
                var itemToReturn = {
                    index: i,
                    template: self.element.find(element.attr('href')),
                    title: element[0].textContent,
                    titleStyles: titleStyles,
                    colorStyle: colorStyle,
                    readOnlyStyle: !item.Enabled,
                    backgroundStyles: backgroundColor,
                    visible: item.Visible,
                    //disabled: !item.Enabled,
                    titleTextEn: element.attr('data-caption-en'),
                    titleTextFr: element.attr('data-caption-fr'),
                    titleTextDe: element.attr('data-caption-de'),
                    titleTextAr: element.attr('data-caption-ar'),
                    titleTextHe: element.attr('data-caption-he'),
                    titleTextSv: element.attr('data-caption-sv')
                };

                if (item.SectionSelected) {
                    if (behavior.Multiple) { selectedItems.push(itemToReturn); }
                    selectedIndex = x;
                }
                return itemToReturn;
            })

            const tabIndex = $(this.element).attr("tabindex");
            $(this.element).removeAttr("tabindex");

            this.element.children("div:first").remove();
            const tabEl = document.createElement('div');
            tabEl.classList.add('customDevExtremeAccordion');
            this.element.append(tabEl);

            let initedIndexes = [];
            let firstinit = true;

            this.accordionEl = new Accordion(this.element.find('.customDevExtremeAccordion'), {
                multiple: behavior.Multiple,
                collapsible: behavior.Collapsible,
                itemTitleTemplate: $('#titleTemplate'),
                items: itemInfo,
                tabIndex: tabIndex,
                deferRendering: false,
                selectedIndex: selectedIndex,
                selectedItems: selectedItems,
                itemTitleTemplate: function (data, index, element) {
                    element[0].style.cssText = data.colorStyle;
                    const item = document.createElement('div');
                    item.classList.add("localizable");
                    item.style.cssText = data.titleStyles;
                    $(item).attr('data-backgroudColor', data.backgroundStyles);
                    $(item).attr('data-caption-en', data.titleText);
                    $(item).attr('data-caption-fr', data.titleTextFr);
                    $(item).attr('data-caption-de', data.titleTextDe);
                    $(item).attr('data-caption-ar', data.titleTextAr);
                    $(item).attr('data-caption-he', data.titleTextHe);
                    $(item).attr('data-caption-sv', data.titleTextSv);
                    $(item).text(data.title)
                    if (data.readOnlyStyle) { $(element).addClass('readOnlyStyle'); }
                    element.append(item);
                },
                onSelectionChanged: function (e) {
                    if (e.addedItems.length > 0) {
                        var index = e.addedItems[0].index;
                        if (!initedIndexes.includes(index)) {
                            self.initControls(index);
                            initedIndexes.push(index);
                        }

                        if (firstinit === false) {
                            self.options.controlState.Behavior.SelectedSectionIndex = index;

                            function loop(self) {
                                setTimeout(function () {
                                    const postData = {
                                        pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                        controlId: self.options.controlState.Id,
                                        selectedIndex: self.options.controlState.Behavior.SelectedSectionIndex
                                    };
                                    utils.callRest("api/accordionsection-active", "PUT", null, false, postData);
                                }, 100);
                            }

                            loop(self);

                        } else
                            firstinit = false;
                    } else {
                        //Item was closed
                    }
                }
            });
            //Deal with issue where accordion height is set to 0 randomly
            setTimeout(function () {
                [...$('.dx-accordion-item')].forEach(div => {
                    if (div.style.cssText.includes('height: 0')) {
                        var newHeight = $(div).find('.dx-accordion-item-title').innerHeight();
                        div.style.height = newHeight + 'px';
                    };
                });
            }, 400);

            Array.from(this.element.find('div[data-backgroudColor]')).forEach(child => {
                var backColorAttr = child.dataset.backgroudcolor;
                if (backColorAttr) {
                    var item = $(child).closest('.dx-item-content')
                    item[0].style.cssText += backColorAttr;
                }
            });

            if (!behavior.Padding) {
                this.element.addClass('nopadding');
            }
            if (behavior.ArrowToLeft) {
                this.element.find('.dx-accordion-item-title').addClass('arrowToLeft');
            }
        },
        expandLazy: function () {
            const self = this;
            if (self.initForChildrenNeeded) {
                this.element.find(".dx-accordion-container .dx-item").each(function (index) {
                    const tchild = self.options.controlState.Behavior.SectionsChild[index].Value;
                    tchild.forEach(function (item) {
                        self.options.bpmsAppForm._initControl(item);
                        self.options.bpmsAppForm._setControlValidators(item);
                    });
                });
                self.initForChildrenNeeded = false;
            }
        },
        _setEnable: function (enable) {
            const self = this;
            self._super(enable);
            if (!self.options.controlState.Behavior.Inited && self.options.controlState.Behavior.SelectedSectionIndex > -1) {
                this.initControls(self.options.controlState.Behavior.SelectedSectionIndex);
                this.accordionEl.option('selectedIndex', self.options.controlState.Behavior.SelectedSectionIndex);

                function loop(self) {
                    setTimeout(function () {
                        const postData = {
                            pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                            controlId: self.options.controlState.Id
                        };
                        utils.callRest("api/accordion-behaviour", "PUT", null, false, postData);
                    }, 100);
                }

                loop(self);
            }
        },

        _setVisible: function (visible) {
            const self = this;
            self._super(visible);
            let anyVis = false;
            for (let i = 0; i < self.options.controlState.Behavior.Sections.length; i++) {
                const accPage = self.options.controlState.Behavior.Sections[i];
                let accPageControlState = null;
                for (let j = 0; j < self.options.bpmsAppForm.dto.formState.ControlStates.length; j++) {
                    const item = self.options.bpmsAppForm.dto.formState.ControlStates[j];
                    if (item.Id === accPage.Name) {
                        accPageControlState = item;
                        break;
                    }
                }

                const accItem = self.element.find('#' + accPage.Name).closest('.dx-item');
                if (accPageControlState.Behavior.Visible) {
                    anyVis = true;
                } else {
                    //if accPage selected but invisible => force to visible
                    if (accItem.hasClass('dx-accordion-item-opened')) {
                        anyVis = true;
                        accPageControlState.Behavior.Visible = true;
                    }
                }
                self.accordionEl.option('items[' + i + '].visible', accPageControlState.Behavior.Visible);
                //self.accordionEl.option('items[' + i + '].disabled', !accPageControlState.Behavior.Enabled);  
            }
            self.accordionEl.option('visible', anyVis);  
        },

        getValue: function () {
            //SelectedSectionIndex
            return this.options.controlState.Value;
        },

        initControls: function (newpage) {
            const self = this;
            const tchild = self.options.controlState.Behavior.SectionsChild[newpage].Value;
            //console.log("Activated page ", newpage, tchild.length, self.options.controlState.Behavior.LazyLoad);

            let isLoadNeeded = false;
            if (self.options.controlState.Behavior.LazyLoad)
                tchild.forEach(function (item) {
                    if (self.options.bpmsAppForm.postponed.indexOf(item) > -1)
                        isLoadNeeded = true;
                });
            //console.log("Activated page", newpage, isLoadNeeded, self.options.controlState.Behavior.LazyLoad);

            if (isLoadNeeded)
                setTimeout(function () {
                    self.options.bpmsAppForm.options.showLoading(true);
                    setTimeout(function () {
                        tchild.forEach(function (item) {
                            //console.log("Activated control", newpage, item);
                            self.options.bpmsAppForm._initControl(item);
                            self.options.bpmsAppForm._setControlValidators(item);
                        });
                        self.options.bpmsAppForm.options.showLoading(false);
                        self.initForChildrenNeeded = false;
                    }, 150);
                }, 150);
            $(window).resize();
        }
    });
})(jQuery);

// RayTab
(function ($, undefined) {
    $.widget('ui.rayTabControl', $.ui.rayControl, {

        tabControlEl: null,
        isChanged: false,
        devExpressTabControl: false,
        init: true,
        lazyLoadin: false,
        isFixed: false,
        isDupHeader: false,
        initForChildrenNeeded: true,
        hiddenTabPages: [],


        _create: function () {
            const self = this;

            let scrollToTabTopOnActiveEnabled = true;

            const fixedTabs = $(this.element).data('fixedtabs');
            this.isFixed = typeof fixedTabs !== 'undefined' && fixedTabs == 'True';
            const dupheader = $(this.element).data('dupheader');
            this.isDupHeader = typeof dupheader !== 'undefined' && dupheader == 'True';
            

            this.devExpressTabControl = true;
            var behavior = this.options.controlState.Behavior;
            if (behavior && behavior.Items && behavior.Items.length > 0) {
                this.devExpressTabControl = true;
            }

            if (this.devExpressTabControl) {
                var selectedIndex = 0;
                var items = behavior.Tabs;
                var itemInfo = Object.keys(items).map(function (x, i) {
                    var item = items[x];

                    var element = self.element.find('li a').eq(i);

                    var titleStyles = '';
                    var backgroundColor = '';
                    if (item.Italic) { titleStyles += 'font-style: italic;'; }
                    if (item.Underline) { titleStyles += 'text-decoration: underline;'; }
                    if (item.Color && item.Color.length > 0) { titleStyles += `color: ${item.Color};`; }
                    if (item.FontFamily && item.FontFamily.length > 0) { titleStyles += `font-family: ${item.FontFamily};`; }
                    if (!item.FontSize || item.FontSize == 0) { item.FontSize = 17; }
                    titleStyles += `font-size: ${item.FontSize}px;`; 
                    if (item.BackgroundColor && item.BackgroundColor.length > 0) {
                        if (item.BackgroundColor.toLowerCase() == 'transparent') {
                            item.BackgroundColor = 'white';
                        }
                        backgroundColor = `background-color: ${item.BackgroundColor} !important;`;
                    }
                    if (item.TabSelected) { selectedIndex = x; }
                    return {    
                        index: i,
                        template: self.element.find(element.attr('href')),
                        //html: $(element.attr('href')).wrap('<p/>').parent().html(),
                        title: element[0].textContent,
                        titleStyles: titleStyles,
                        readOnlyStyle: !item.Enabled,
                        backgroundStyles: backgroundColor,
                        visible: item.Visible,
                        titleTextEn: element.attr('data-caption-en'),
                        titleTextFr: element.attr('data-caption-fr'),
                        titleTextDe: element.attr('data-caption-de'),
                        titleTextAr: element.attr('data-caption-ar'),
                        titleTextHe: element.attr('data-caption-he'),
                        titleTextSv: element.attr('data-caption-sv')
                    };
                })

                const tabIndex = $(this.element).attr("tabindex");
                $(this.element).removeAttr("tabindex");

                this.element.children("div:first").remove();
                const tabEl = document.createElement('div');
                tabEl.classList.add('customDevExtremeTabPanel');
                this.element.append(tabEl);

                let initedIndexes = [];
                let firstinit = true;

                this.tabControlEl = new TabPanel(this.element.find('.customDevExtremeTabPanel'), {

                    loop: behavior.Looping,
                    //animationEnabled: behavior.Animation,
                    swipeEnabled: false,
                    itemTitleTemplate: $('#titleTemplate'),
                    items: itemInfo,
                    tabIndex: tabIndex,
                    deferRendering: false,
                    selectedIndex: selectedIndex,
                    itemTitleTemplate: function (data, index, element) {
                        const item = document.createElement('div');
                        item.classList.add("localizable");
                        item.style.cssText = data.titleStyles;
                        $(item).attr('data-backgroudColor', data.backgroundStyles);
                        $(item).attr('data-caption-en', data.titleText);
                        $(item).attr('data-caption-fr', data.titleTextFr);
                        $(item).attr('data-caption-de', data.titleTextDe);
                        $(item).attr('data-caption-ar', data.titleTextAr);
                        $(item).attr('data-caption-he', data.titleTextHe);
                        $(item).attr('data-caption-sv', data.titleTextSv);
                        $(item).text(data.title)
                        if (data.readOnlyStyle) { $(element).parent().addClass('readOnlyStyle'); }
                        element.append(item);
                    },
                    onSelectionChanged: function (e) {
                        var index = e.addedItems[0].index;
                        if (!initedIndexes.includes(index)) {
                            self.initControls(index);
                            initedIndexes.push(index);
                        }

                        if (firstinit === false) {
                            self.options.controlState.Behavior.SelectedTabIndex = index;

                            function loop(self) {
                                setTimeout(function () { 
                                    const postData = {
                                        pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                        controlId: self.options.controlState.Id,
                                        selectedIndex: self.options.controlState.Behavior.SelectedTabIndex
                                    };
                                    utils.callRest("api/tab-active", "PUT", null, false, postData);
                                }, 100);
                            }

                            loop(self);
                             
                        } else
                            firstinit = false;

                        if (self.isDupHeader) {
                            var index = e.addedItems[0].index;
                            self.element.find('.dx-tabpanel-tabs.clone .dx-tabs-wrapper').eq(0).children('div.dx-item.dx-tab-selected').removeClass('dx-tab-selected');
                            self.element.find('.dx-tabpanel-tabs.clone .dx-tabs-wrapper').eq(0).children('div.dx-item').eq(index).addClass('dx-tab-selected');
                        }
                    },
                });

                Array.from(this.element.find('div[data-backgroudColor]')).forEach(child => {
                    var backColorAttr = child.dataset.backgroudcolor;
                    if (backColorAttr) {
                        var item = $(child).closest('.dx-item')
                        item[0].style.cssText += backColorAttr;
                    }
                });
                if (this.isDupHeader) {
                    let elementToClone = this.element.find('.dx-tabpanel-tabs:not(.clone)').eq(0);
                    if (elementToClone && !elementToClone.hasClass('clone')) {
                        let clonedElement = elementToClone.clone();
                        clonedElement.addClass("clone");
                        elementToClone.closest('.customDevExtremeTabPanel').append(clonedElement);
                        this.element.find('.dx-tabpanel-tabs.clone .dx-tabs-wrapper').eq(0).children('div.dx-item').click(function () {
                            $(this).siblings('.dx-tab-selected').removeClass('dx-tab-selected');
                            var i = $(this).index();
                            self.tabControlEl.option('selectedIndex', i);
                            $(this).addClass('dx-tab-selected');
                        });
                    }
                }

                if (this.isFixed) {
                    this.element.find('.customDevExtremeTabPanel').addClass('fixedNavBar');
                }

                if (this.isFixed) {
                        let navbar = this.element.find('.dx-tabpanel-tabs').eq(0);
                        var tabLevel = this.levelOfCurrentTab(this.element);
                        var stickyPropApplied = false;
                        var nonStickyPropApplied = false;
                    var scrollFunction = function () {
                            var sticky = window.pageYOffset + self.element[0].getBoundingClientRect().top;
                            var calculatedTopProprty = $('.navbar_custom').height() + self.calculateTabsOffset(self.element, tabLevel - 1);
                            var elHeight = self.element.height();
                            if (sticky > 0 && window.pageYOffset + calculatedTopProprty >= sticky && window.pageYOffset + (navbar.height() * 2) < sticky + elHeight) {
                                if (!stickyPropApplied) {
                                    navbar.addClass("sticky");
                                    navbar[0].style.top = `${calculatedTopProprty}px`;
                                    navbar[0].style.width = `${navbar.parent().width()}px`;
                                    stickyPropApplied = true;
                                    nonStickyPropApplied = false;
                                }

                            } else {
                                if (!nonStickyPropApplied) {
                                    navbar.removeClass("sticky");
                                    navbar[0].style.top = '';
                                    navbar[0].style.width = '';
                                    stickyPropApplied = false;
                                    nonStickyPropApplied = true;
                                }
                            }
                        };
                        window.addEventListener('scroll', scrollFunction);
                    //Transition property breakes fixed positioning
                    this.element.closest('.dx-multiview-item').addClass('notransition');
                    this.element.closest('.dx-multiview-item-container').addClass('notransition');
                }
                var tabTitleTextIsHdden = false;
                this.element.find(".dx-tabs .dx-tab").each(function (index) {
                    var item = $(this).find('.dx-item-content');
                    if (item && item[0].scrollWidth > item[0].offsetWidth) {
                        tabTitleTextIsHdden = true;
                        return false;
                    }
                });

                var tabsWrapperEl = this.element.find('.dx-tabs-wrapper');
                if (tabTitleTextIsHdden || tabsWrapperEl.width() > tabsWrapperEl.parents('.dx-tabpanel-tabs').width())
                {
                    tabsWrapperEl.addClass('wrap'); 
                }
                //By having white-space break-spaces, means when title text takes more space than needed it will take more than 1 line
                //So we take the height of every title header and if min not equals the max -> means height is different, so at least one item takes more than 1 line
                //In this case we wrap the content to fit them in the same line
                var titlesArray = tabsWrapperEl.children('.dx-item.dx-tab:not(.dx-state-invisible)').toArray().map(x => x.offsetHeight);
                if (Math.min.apply(null, titlesArray) != Math.max.apply(null, titlesArray)) {
                    tabsWrapperEl.addClass('wrap');
                }

                $(window).resize(function () {
                    if (tabsWrapperEl.width() >= tabsWrapperEl.parents('.dx-tabpanel-tabs').width() || tabTitleTextIsHdden) {
                        tabsWrapperEl.addClass('wrap');
                    } else {
                        tabsWrapperEl.removeClass('wrap');
                    }
                });


                var tabPanelTemplateClass = "minimalistTab";
                if (!behavior.FloatingTabMenu) {
                    tabPanelTemplateClass = "notMinimalistTab";
                    this.element.addClass('nopadding');
                }
                this.element.find('.dx-tabpanel').eq(0).addClass(tabPanelTemplateClass);

                if (self.scrollToTabTopOnActiveEnabled) {
                    let top = $(self.element.find$('.dx-item:first')[0]).offset().top - 100;
                    if (top < 0) top = 0;

                    //$('html, body').scrollTop(top);
                    scrollTo(0, top);
                }
            }
        },

        expandLazy: function () {
            const self = this;
            if (self.initForChildrenNeeded) {
                this.element.find(".dx-tabs .dx-tab").each(function (index) {
                    const tchild = self.options.controlState.Behavior.TabsChild[index].Value;
                    tchild.forEach(function (item) {
                        //console.log("Activated control", newpage, item);
                        self.options.bpmsAppForm._initControl(item);
                        self.options.bpmsAppForm._setControlValidators(item);
                    });
                });
                self.initForChildrenNeeded = false;
            }
        },

        levelOfCurrentTab(el) {
            var level = 0;

            var current = el;
            while (current.closest('.RayTabControl').length > 0) {
                current = current.parent().closest('.RayTabControl');
                level++;
            }
            return level;
        },
        calculateTabsOffset(el, nbrOffsets) {
            var total = 0;
            var items = el.parents('.RayTabControl');
            for (var i = 0; i < nbrOffsets; i++) {
                var item = items.eq(i).find('.dx-tabpanel-tabs');
                if (item.closest('.RayTabControl').attr('data-fixedTabs') == 'True') {
                    total = total + item.height();
                }
            }
            return total;
        },
        _setEnable: function (enable) {
            const self = this;
            self._super(enable);
            if (!self.options.controlState.Behavior.Inited && self.options.controlState.Behavior.SelectedTabIndex > -1) {
                //console.log("Activated tab page", self.options.controlState.Id, self.options.controlState.Behavior.SelectedTabIndex, self.options);
                this.initControls(self.options.controlState.Behavior.SelectedTabIndex);
                self.scrollToTabTopOnActiveEnabled = false;
                this.tabControlEl.option('selectedIndex', self.options.controlState.Behavior.SelectedTabIndex);
                self.scrollToTabTopOnActiveEnabled = true;

                function loop(self) {
                    setTimeout(function () {
                        const postData = {
                            pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                            controlId: self.options.controlState.Id
                        };
                        utils.callRest("api/tab-behaviour", "PUT", null, false, postData);
                    }, 100);
                }

                loop(self);
            }
        },

        _setVisible: function (visible) {
            const self = this;
            self._super(visible);
            let anyVis = false;
            for (let i = 0; i < self.options.controlState.Behavior.Tabs.length; i++) {
                const tabPage = self.options.controlState.Behavior.Tabs[i];
                let tabpageControlState = null;
                for (let j = 0; j < self.options.bpmsAppForm.dto.formState.ControlStates.length; j++) {
                    const item = self.options.bpmsAppForm.dto.formState.ControlStates[j];
                    if (item.Id === tabPage.Name) {
                        tabpageControlState = item;
                        break;
                    }
                }
                
                if (tabpageControlState.Behavior.Visible) {
                    anyVis = true;
                } else {
                    //if tabpage selected in designer but invisible => force to visible
                    if (tabPage.TabSelected) {
                        anyVis = true;
                        tabpageControlState.Behavior.Visible = true;
                    } else {
                        //This tab will be hidden, and if it was selected, select next one
                        self.hiddenTabPages.push(tabPage.Name);

                        const tabItem = self.element.find('#' + tabPage.Name).closest('.dx-tabpanel-container .dx-item');
                        if (tabItem.hasClass('dx-item-selected')) {
                            var firstAcceptableTab = self.options.controlState.Behavior.Tabs.filter(x => x.Visible && !self.hiddenTabPages.includes(x.Name))[0];
                            if (firstAcceptableTab) {
                                self.tabControlEl.option('selectedIndex', self.options.controlState.Behavior.Tabs.indexOf(firstAcceptableTab));
                            }
                        }
                    }
                }
                self.tabControlEl.option('items[' + i + '].visible', tabpageControlState.Behavior.Visible);
                //self.tabControlEl.option('items[' + i + '].disabled', !tabpageControlState.Behavior.Enabled);  
            }
            self.tabControlEl.option('visible', anyVis);  
        },

        getValue: function () {
            //SelectedTabIndex
            return this.options.controlState.Value;
        },

        initControls: function (newpage) {
            const self = this;
            const tchild = self.options.controlState.Behavior.TabsChild[newpage].Value;
            //if (localStorage.dbpDebugMode === 'true')
            //    console.log("TabPanel: Activated page ", newpage, tchild.length, self.options.controlState.Behavior.LazyLoad);

            let isLoadNeeded = false;
            if (self.options.controlState.Behavior.LazyLoad)
                tchild.forEach(function (item) {
                    if (self.options.bpmsAppForm.postponed.indexOf(item) > -1)
                        isLoadNeeded = true;
                });
            //console.log("Activated page", newpage, isLoadNeeded, self.options.controlState.Behavior.LazyLoad);

            if (isLoadNeeded)
                setTimeout(function () {
                    let startLoad = new Date().getTime();
                    self.options.bpmsAppForm.options.showLoading(true);
                    setTimeout(function () {
                        //if (localStorage.dbpDebugMode === 'true')
                        //    console.log("TabPanel: init control", newpage);
                        tchild.forEach(function (item) {
                            self.options.bpmsAppForm._initControl(item);
                            self.options.bpmsAppForm._setControlValidators(item);
                        });
                        self.options.bpmsAppForm.options.showLoading(false);
                        self.initForChildrenNeeded = false;
                        if (localStorage.dbpDebugMode === 'true') {
                            let elapsed = new Date().getTime() - startLoad;
                            console.log(`Tab page ${self.options.controlState.Id}.${newpage} init: ${elapsed} ms`);
                        }
                    }, 150);
                }, 150);
            $(window).resize();
        }
    });
})(jQuery);

// RayTabPage
(function ($, undefined) {
    $.widget('ui.rayTabPage', $.ui.rayControl, {
        _create: function () {
            this.element.attr('serverId', this.options.controlState.Id);
        },
        getValue: function () {
            return this.options.controlState.Value;
        },
        _setVisible: function (visible) {
            //this.element.css('visibility', visible ? 'visible' : 'hidden');
            //this.element.css('display', 'none');
        },
        _setEnable: function (enable) {
            if (enable) {
                this.element.removeAttr('readonly');
            } else {
                this.element.attr('readonly', 'readonly');
            }
            this._setEnableDefault(this.element, enable);
        },
        setCaption: function (cs) {
            if (cs.Caption == null)
                return;
            $(`ul.ui-tabs-nav li[aria-controls="${cs.Id}"]`).find('a').text(cs.Caption);
        },
        clearValidators: function () { }
    });
})(jQuery);

// AccordionSection
(function ($, undefined) {
    $.widget('ui.accordionSection', $.ui.rayControl, {
        _create: function () {
            this.element.attr('serverId', this.options.controlState.Id);
        },
        getValue: function () {
            return this.options.controlState.Value;
        },
        _setVisible: function (visible) {
            //this.element.css('visibility', visible ? 'visible' : 'hidden');
            //this.element.css('display', 'none');
        },
        _setEnable: function (enable) {
            if (enable) {
                this.element.removeAttr('readonly');
            } else {
                this.element.attr('readonly', 'readonly');
            }
            this._setEnableDefault(this.element, enable);
        },
        setCaption: function (cs) {
            if (cs.Caption == null)
                return;
            //To see what it does
            $(`ul.ui-tabs-nav li[aria-controls="${cs.Id}"]`).find('a').text(cs.Caption);
        },
        clearValidators: function () { }
    });
})(jQuery);

(function ($, undefined) {
    var eventAttached;
    $.widget('ui.rayGanttChart', $.ui.rayControl, {
        settings: null,
        loading: false,
        _create: function () {
            if (this.options.isPreview)
                return;
            const element = this.element;
            element.html('');
            eventAttached = null;
        },
        getValue: function () {
            return null;
        },
        setState: function (cs) {

            if (this.loading === true)
                return;

            this.loading = true;

            const self = this;
            const params = {
                pageInstance: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                controlId: self.options.controlState.Id,
                binId: self.options.controlState.BindingId
            };

            //window.gantt = null;
            //delete window['gantt'];

            //gantt = null;
            //delete gantt;

            $.getScript("JScript/gantt/dhtmlxgantt.js").done(function (res) {
                gantt = gantt || window.gantt;

                utils.callWebAPIp("api/gantt/gettasks", params, "POST")
                    .done(function (res) {
                        self.loading = false;

                        let tasks = { data: res.d.tasks };
                        let vacations = [];
                        let colorStyles = res.d.colors;

                        for (let i = 0; i < res.d.vacations.length; i++)
                            vacations.push(new Date(res.d.vacations[i]));

                        gantt.config.scale_height = 50;
                        gantt.templates.date_scale = null;
                        //gantt.config.grid_width = 0;
                        gantt.config.readonly = true;
                        gantt.config.show_errors = false; // set to false based on suggested solution in  https://docs.dhtmlx.com/gantt/api__gantt_gettask.html
                        gantt.i18n.setLocale(payload.currentLocale.Language);

                        //default columns definition
                        gantt.config.columns = [
                            { name: "text", width: "*", tree: true },
                            { name: "weight", label: i18n.$t("priority"), width: 44, align: "center" }
                        ];

                        gantt.templates.scale_cell_class = function (date) {
                            for (let j = 0; j < vacations.length; j++)
                                if (vacations[j].getTime() == date.getTime())
                                    return "weekend";
                        };
                        gantt.templates.timeline_cell_class = function (item, date) {
                            for (let j = 0; j < vacations.length; j++)
                                if (vacations[j].getTime() == date.getTime())
                                    return "weekend";
                        };

                        if (cs.Scale === 'Months') {
                            gantt.config.scale_unit = "year";
                            gantt.config.step = 1;
                            gantt.config.date_scale = "%Y";
                            gantt.config.min_column_width = 50;

                            gantt.config.subscales = [
                                { unit: "month", step: 1, date: "%F" }
                            ];
                        } else if (cs.Scale === "Years") {

                            gantt.config.scale_unit = "year";
                            gantt.config.step = 1;
                            gantt.config.date_scale = "%Y";
                            gantt.config.min_column_width = 50;

                        } else if (cs.Scale === 'Days') {

                            gantt.config.scale_unit = "year";
                            gantt.config.step = 1;
                            gantt.config.date_scale = "%Y";
                            gantt.config.min_column_width = 50;

                            gantt.config.subscales = [
                                { unit: "month", step: 1, date: "%F", css: gantt.templates.scale_cell_classscale_cell_class }
                                , { unit: "day", step: 1, date: "%d", css: gantt.templates.scale_cell_class }
                            ];

                        } else if (cs.Scale === 'Weeks') {
                            gantt.config.scale_unit = "week";
                            gantt.config.date_scale = "Week #%W";
                        } else if (cs.Scale === 'Hours') {
                            gantt.config.scales = [
                                { unit: "day", format: "%F %d" },
                                { unit: "hour", step: 3, format: "%H:%i" }
                            ]
                        }

                        if (colorStyles && colorStyles.length > 0) {
                            for (let ti = 0; ti < tasks.data.length; ti++) {
                                var task = tasks.data[ti];
                                var isApplyStyle = false;
                                for (let csi = 0; csi < colorStyles.length; csi++) {
                                    var colorStyle = colorStyles[csi];
                                    if (colorStyle.AttibuteField && colorStyle.AttibuteField != "") {
                                        if (colorStyle.AttibuteField in task.colorEvalFields) {
                                            let f = `return ${colorStyle.Condition.replace(/value/gi, task.colorEvalFields[colorStyle.AttibuteField]).replace(/=/g, '==').replace(/>==/g, '>=')
                                                .replace(/<==/g, '<=').replace(/and/gi, '&&').replace(/or/gi, '||')};`;
                                            let cf = new Function(f);
                                            isApplyStyle = cf.call(this);
                                            if (isApplyStyle) {
                                                task.color = colorStyle.ColorHtml;
                                                task.textColor = colorStyle.TextColorHtml;
                                                task.progressColor = colorStyle.ProgressColorHtml;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        gantt.init(self.element[0]);
                        gantt.parse(tasks);

                        if (cs.EditParams && !eventAttached) {
                            eventAttached = gantt.attachEvent("onTaskDblClick", function (id, e) {
                                var params = {
                                    pageInsId: self.options.bpmsAppForm.dto.formState.PageInstanceId,
                                    controlId: cs.Id,
                                    selectedId: id,
                                    bindingId: cs.BindingId
                                };

                                utils.callWebAPIp("api/gantt/edittask", params, "POST", false)
                                    .done(function () {
                                        cs.EditParams = cs.EditParams.replace('@nestedClientId', cs.Id);

                                        const modaldiv = $('<div id="modalDialog" />');
                                        const params = {
                                            nested: JSON.parse(cs.EditParams),
                                            parentBpmsAppForm: self.options.bpmsAppForm
                                        };
                                        modaldiv.bpmsAppForm(params);

                                        return true;
                                    });
                            });
                        }
                    })
                    .fail(function () {
                        self.loading = false;
                    });

                self.element.toggle(cs.Behavior.Visible);
            }).fail(function () {
                self.loading = false;
            });


        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayImageControl', $.ui.rayControl, {
        _create: function () {
            if (this.options.isPreview) {
                $(this.element).css('border', 'black solid 1px');
            }
        },
        _setVisible: function (visible) {
            this._super(visible);
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.RayClientChart', $.ui.rayControl, {
        nestedEl: null,
        point: null,
        chartId: null,
        mergChart: null,
        DELAY: 700,
        clicks: 0,
        timer: null,
        intervalId: null,
        getThemeObject: function (themeName) {
            switch (themeName) {
                case 'chartTheme_Default':
                    return chartTheme_Default;
                case 'chartTheme_DarkBlue':
                    return chartTheme_DarkBlue;
                case 'ChartTheme_DarkGreen':
                    return ChartTheme_DarkGreen;
                case 'chartTheme_DarkUnica':
                    return chartTheme_DarkUnica;
                case 'chartTheme_Gray':
                    return chartTheme_Gray;
                case 'chartTheme_GridLight':
                    return chartTheme_GridLight;
                case 'chartTheme_Grid':
                    return chartTheme_Grid;
                case 'chartTheme_SandSignika':
                    return chartTheme_SandSignika;
                case 'chartTheme_Skies':
                    return chartTheme_Skies;
                case 'chartTheme_SparkLine':
                    return chartTheme_SparkLine;
            }
        },
        setChartParamSession: function (clientId, parameters) {
            //utils.callWebService('webservices/ChartService.asmx', 'setParamSession', {
            utils.callWebAPIp("api/chart/setparams", {
                parameters: parameters,
                pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                clientId: clientId,
                bindingId: this.options.controlState.BindingId
            }, "POST").done().fail();
        }
        , _create: function () {
            const self = this;
            const op = this.options;
            const cs = this.options.controlState;
            if (cs.ChartInterval) {
                const intervalTime = cs.IntervalTime;
                setInterval(() => {
                    cs.DoInit = true;
                    self._SetRealTimeChart(self);
                }, intervalTime);
            }
        }
        , _setValue: function () {
            const self = this;
            if (self.options.controlState.DoInit != null && !self.options.controlState.DoInit)
                return;
            this._DrawChart(self);

        }
        , _SetRealTimeChart: function (_sendChart) {
            this._DrawChart(_sendChart);
        }
        , _DrawChart: function (_sendChart) {
            const self = _sendChart;
            const op = _sendChart.options;
            const cs = _sendChart.options.controlState;

            if (this.chartId == null)
                this.chartId = _sendChart.options.controlState.Value;
            //utils.callWebService('webservices/ClientChartService.asmx', 'GetChartData',
            //    { chartId: this.chartId, pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId }, true)
            utils.callWebAPIp("api/chart/data", { chartId: this.chartId, clientId: self.options.controlState.Id, pageInstanceId: self.options.bpmsAppForm.dto.formState.PageInstanceId }, "POST")
                .done(function (result) {
                    let chartOption = jQuery.parseJSON(result.d);
                    chartOption.plotOptions.series.point = {
                        events: {
                            cursor: 'pointer',
                            click: function (e) {
                                self.clicks++;  //count clicks
                                if (self.clicks === 1) {
                                    self.timer = setTimeout(function () {
                                        if (self.options.controlState.Behavior.AutoUpdate) {
                                            self._click(e.point, chart.options.colors);
                                        }
                                        self.clicks = 0;  //after action performed, reset counter

                                    }, self.DELAY);

                                } else {

                                    clearTimeout(self.timer);  //prevent single-click action

                                    //perform double-click action
                                    if (self.options.controlState.HasChild) {
                                        self._dblclick(e.point);

                                    }
                                    self.clicks = 0;  //after action performed, reset counter
                                }
                            }
                        }
                    }

                    //let minX = 0;
                    let minY = 0;
                    for (let s = 0; s < chartOption.series.length; s++) {
                        let data = chartOption.series[s].data;
                        $.each(chartOption.series[s].data, function (d) {
                            if (chartOption.xAxis[s])
                                chartOption.xAxis[s].categories.push(chartOption.series[s].data[d].name);
                        });
                        for (let a = 0; a < data.length; a++) {
                            let colorReg = /(\d+)(\s)*,(\s)*(\d+)(\s)*,(\s)*(\d+)/gmi;
                            if (colorReg.test(data[a].color)) {
                                data[a].color = `rgb(${data[a].color})`
                            }
                            if (data[a].y < minY) minY = parseInt(data[a].y);
                            //if (data[a].x < minX) minX = parseInt(data[a].x);
                        }
                    }
                    $.each(chartOption.series, function (i) {
                        if (chartOption.series[i].dataLabels != null) {
                            chartOption.series[i].dataLabels.vertivalAlign = 'bottom';
                            chartOption.series[i].dataLabels.align = 'center';
                            chartOption.series[i].dataLabels.useHTML = true;
                        }
                        $.each(chartOption.series[i].data, function (j) {
                            if (chartOption.series[i].data[j] != null) {
                                if (chartOption.series[i].data[j].color == null)
                                    delete chartOption.series[i].data[j].color;
                                if (chartOption.series[i].data[j].x == -1)
                                    delete chartOption.series[i].data[j].x;
                            }
                        });
                    });

                    //chartOption.xAxis[0].title.style.direction='rtl';
                    //chartOption.xAxis[1].categories = [];

                    self.element.css('border', 'red solid 0px');

                    let chartTheme = jQuery.extend(true, {}, self.getThemeObject(chartOption.chartTheme));
                    //Highcharts.getOptions().exporting.buttons.contextButton.menuItems
                    chartTheme.lang = {
                        loading: i18n.$t('chart_control.loading'),
                        months: [i18n.$t('month_january'), i18n.$t('month_february'), i18n.$t('month_march'), i18n.$t('month_april'), i18n.$t('month_may '), i18n.$t('month_june'), i18n.$t('month_july'), i18n.$t('month_august'), i18n.$t('month_september'), i18n.$t('month_october'), i18n.$t('month_november'), i18n.$t('month_december')],
                        weekdays: [i18n.$t('weekday_sunday'), i18n.$t('weekday_monday'), i18n.$t('weekday_tuesday'), i18n.$t('weekday_wednesday'), i18n.$t('weekday_thursday'), i18n.$t('weekday_friday'), i18n.$t('weekday_saturday')],
                        shortMonths: [i18n.$t('short_month_january'), i18n.$t('short_month_february'), i18n.$t('short_month_march'), i18n.$t('short_month_april'), i18n.$t('short_month_may '), i18n.$t('short_month_june'), i18n.$t('short_month_july'), i18n.$t('short_month_august'), i18n.$t('short_month_september'), i18n.$t('short_month_october'), i18n.$t('short_month_november'), i18n.$t('short_month_december')],

                        exportButtonTitle: i18n.$t('chart_control.exportButtonTitle'),
                        printButtonTitle: i18n.$t('chart_control.printButtonTitle'),
                        rangeSelectorFrom: i18n.$t('chart_control.rangeSelectorFrom'),
                        rangeSelectorTo: i18n.$t('chart_control.rangeSelectorTo'),
                        rangeSelectorZoom: i18n.$t('chart_control.rangeSelectorZoom'),
                        downloadPNG: i18n.$t('chart_control.downloadPNG'),
                        downloadJPEG: i18n.$t('chart_control.downloadJPEG'),
                        downloadPDF: i18n.$t('chart_control.downloadPDF'),
                        downloadSVG: i18n.$t('chart_control.downloadSVG'),
                        downloadXLS: i18n.$t('chart_control.downloadXLS'),
                        downloadCSV: i18n.$t('chart_control.downloadCSV'),

                        printChart: i18n.$t('chart_control.printChart'),
                        contextButtonTitle: i18n.$t('chart_control.contextButtonTitle'),
                        // resetZoom: "Reset",
                        // resetZoomTitle: "Reset,
                        // thousandsSep: ".",
                        // decimalPoint: ','
                    };

                    let mergChart = Highcharts.merge(chartOption, chartTheme);
                    if (mergChart.series[0]) {
                        mergChart.xAxis[0].labels.useHTML = true;
                        mergChart.yAxis[0].labels.useHTML = true;
                        mergChart.title.useHTML = true;
                        mergChart.legend.useHTML = true;
                        if (mergChart.tooltip != null) {
                            if (mergChart.tooltip.enabled == false || (mergChart.tooltip.enabled == true && mergChart.tooltip.pointFormat == "")) {
                                delete mergChart.tooltip.enabled;
                                delete mergChart.tooltip.pointFormat;
                            }
                        }
                        if (chartOption.colors != null)
                            mergChart.colors = chartOption.colors;
                        if (mergChart.series[0].type !== 'solidgauge') {
                            mergChart.yAxis[0].min = minY;
                            mergChart.yAxis[0].max = null;
                            mergChart.yAxis[1].min = minY;
                            mergChart.yAxis[1].max = null;

                        } else {
                            mergChart.yAxis[1] = {};
                            mergChart.yAxis[0].minorTickInterval = null;
                            mergChart.series[0].dataLabels = {
                                format: `<div style="text-align:center"><span style="font-size:20px;color:${(Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'}">{y:.1f}</span><br/></div>`
                                , useHTML: true
                            };
                            if (mergChart.tooltip != null) {
                                mergChart.tooltip.enabled = false;
                                mergChart.tooltip.useHTML = true;
                            } else {
                                mergChart.tooltip = { enabled: false, useHTML: true };
                            }

                        }
                        if (mergChart.series[0].type === 'solidgauge') {
                            let yval = mergChart.series[0].data[0].y;
                            mergChart.series[0].data[0].y = 0;
                        }
                    }

                    $(self.element[0]).html('');
                    mergChart.tooltip.useHTML = true;
                    $(self.element[0]).highcharts(mergChart);
                    let chart = $(self.element[0]).highcharts();
                    if (mergChart.series[0] && mergChart.series[0].type === 'solidgauge') {
                        let point = chart.series[0].points[0].update(yval);
                    }

                    let isFixed = false;
                    setInterval(function () {
                        if ($(self.element).is(':visible')) {
                            if (isFixed == false) {
                                chart.reflow();
                                isFixed = true;
                            }
                        } else {
                            isFixed = false;
                        }
                    }, 300);
                }).fail(function (a) {
                    self.element.css('border', 'red solid 1px');
                    self.element.html(`${i18n.$t('chart_control.error_in_Get_Data') + a.responseJSON.Message}<label style='visibility: hidden'>${a.responseJSON.StackTrace}</label>`);
                }, utils.getRequestHeader());
        }
        , _click: function (data, colors) {
            const self = this;
            const op = this.options;
            const cs = this.options.controlState;
            if (self.options.controlState.Behavior.AutoUpdate) {
                let colorPoint = colors[0];
                if (data.color != null && data.color != '')
                    colorPoint = data.color;
                self.setChartParamSession(self.options.controlState.Id, `SeriesName=${data.series.name},y=${data.y},x=${data.name},id=${data.id},Color=${colorPoint}`);
                self._trigger('rayControlChanged', {}, cs);
            }
        },
        _dblclick: function (data) {
            const self = this;
            const op = this.options;
            const cs = this.options.controlState;
            self.nestedEl = $('<div id="modalDialog" />');
            if (self.options.controlState.HasChild) {
                self.setChartParamSession(self.options.controlState.Id, `SeriesName=${data.series.name},y=${data.y},x=${data.name},id=${data.id}`);

                const params = {
                    nested: {
                        isNestedPage: true,
                        appName: cs.AppName,
                        pageInsId: op.bpmsAppForm.dto.formState.PageInstanceId,
                        Id: cs.Id,
                        objstate: 'ChartView',
                        title: ` ${data.name}`
                    }
                };
                params.parentBpmsAppForm = op.bpmsAppForm;
                params.nested.invisibleSaveButton = true;
                params.nested.cancelbuttonTitle = i18n.$t('return');
                self.nestedEl.bpmsAppForm(params);
            }

        },
        _destroy: function () {
            clearInterval(this.intervalId);
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayImageViewer', $.ui.rayControl, {

        _create: function () {
            if (this.options.controlState)
                this.lastValue = this.options.controlState.Value;
            //this.setState(this.options.controlState);
        },

        _setVisible: function (visible) {
            this.element.css('visibility', visible ? 'visible' : 'hidden');
            this.element.find("li").css('visibility', visible ? 'visible' : 'hidden');
        },

        _setValue: function () {
            const self = this;
            const cs = this.options.controlState;
            this.element.empty();
            if (cs.Value) {
                let height = this.element.css('height');
                if (height)
                    height = height.replace('px', '') - 22;
                utils.callWebAPIp("api/file/getGroupPreview", {
                    groupId: cs.Value,
                    pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId
                }, "GET").done(function (result) {
                    const ul = $('<ul></ul>');

                    //if ($(self.element).find("ul").length !== 0)
                    //    return;

                    for (let i = 0; i < result.d.length; i++) {
                        const imageSrc = utils.isImage(result.d[i].Name) && result.d[i].Value != '' ? `data:${utils.getFileExtension(result.d[i].Name)};base64,${result.d[i].Value}` : "dist/noimage.png";
                        const imageItem = $('<img/>').attr('src', imageSrc);
                        if (height)
                            imageItem.css('height', height);
                        const anchor = $('<a></a>').attr('href', imageSrc).append(imageItem).attr('title', result.d[i].Name);
                        ul.append($('<li></li>').append(anchor));
                    }
                    if (self.element)
                        self.element.append(ul);

                    if (self.options.controlState.Behavior.Visible)
                        self.element.rayimageviewer();

                    //self._setVisible
                }).fail(function () {
                    utils.showAjaxError(Array.prototype.slice.call(arguments, 0));
                });
            }
        }
    });
})(jQuery);

(function ($, undefined) {
    $.widget('ui.rayCanvasPanel', $.ui.rayControl, {
        currentCanvas: {},
        containerId: '',
        _create: function () {
            this.options.controlState.Value = this.lastValue = "";

            const isRightToLeft = payload.currentLocale.IsRightToLeft;
            const clearTooltip = i18n.$t('rule_management.rule_modal.clear_tooltip');

            this.containerId = $(this.element).attr('id');
            //console.log("Canvas init", this.containerId, isRightToLeft, clearTooltip);
            const rayDrawingCanvas = new RayDrawingCanvas(this, this.containerId, isRightToLeft, clearTooltip);
            //console.log("Canvas init 2", rayDrawingCanvas);
            this.currentCanvas = rayDrawingCanvas;
            rayDrawingCanvas.init();
        },
        _setValue: function (value) {
            this.currentCanvas.fillCanvas(value);
        },
        _setEnable: function (enable) {
            //console.log("Canvas enable", this.containerId, enable);
            this.currentCanvas.setEnable(enable);
            this._setEnableDefault(this.element, enable);
        },
        getValue: function () {
            const cs = this.options.controlState;
            return cs.Value;
        },
        getControlValue: function () {
            return this.getValue();
        },
        _setVisible: function (visible) {
            $(`#parent${this.containerId}`).css('display', visible ? '' : 'none');
        },
        requiredFieldValidator: function (validator) {
            const currentValue = this.getValue();

            return !!currentValue;
        }
    });
})(jQuery);
