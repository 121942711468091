import NewForm from '../forms/newform/newForm.vue';
import utils from 'jsf/utils.js';
import { DxForm, DxItem, DxSimpleItem, DxGroupItem, DxButtonItem, DxButtonOptions, DxLabel, DxTabbedItem, DxTab } from "devextreme-vue/form";
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxDateBox, DxValidationSummary } from "devextreme-vue";
import { DxValidator, DxRequiredRule, DxCompareRule } from 'devextreme-vue/validator';
import themes from "devextreme/ui/themes";

export default {
    components: {
        DxForm, DxItem, DxSimpleItem, DxGroupItem, DxButtonItem, DxButtonOptions, DxLabel, DxTabbedItem, DxTab,
        DxSelectBox, DxDateBox, DxCheckBox, DxTextBox, DxButton, DxNumberBox,
        DxValidator, DxRequiredRule, DxValidationSummary, DxCompareRule,
        NewForm
    },

    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,

            defaultBackgroundColor: '#0371c3',
            defaultMandatoryColor: '#dbf0ff',
            defaultReadonlyTransparent: true,

            uiData: {
                debugMode: false,
                debugModelDescription: '',
                theme: '',
                backgroundColor: this.defaultBackgroundColor,
                backgroundColorDescription: '',
                mandatoryColor: this.defaultMandatoryColor,
                mandatoryColorDescription: '',
                readonlyTransparent: this.defaultReadonlyTransparent,
                readonlyTransparentDescription: '',
                restoreDefaultValuesBtnText: '',
                autoSaveEnabled: false,
                autoSaveEnabledDescription: '',
                autoSaveInterval: 5,
                autoSaveIntervalDescription: '',
                auditTrailEnabled: true,
                auditTrailEnabledDescription: '',
                groupSystem: '',
                groupColors: '',
                groupAutoSave: '',
                groupMisc: '',
                EPCurl: '',
                EPCuser: '',
                EPCpassword: '',
                EPCEnvironment: '',
                EPCEnvironments: [],
                savedMessage: '',
                saveButtonText: ''
            },
            saveButtonOptions: {
                text: "Save",
                type: 'success',
                useSubmitBehavior: true,
                elementAttr: { class: 'btn btn-primary btn-block' }
            },
            allthemes: [{
                id: 'generic.light',
                name: 'Light'
            }, {
                id: 'generic.dark',
                name: 'Dark'
            }, {
                id: 'generic.light.compact',
                name: 'Light compact'
            }, {
                id: 'material.blue.light',
                name: 'Material UI Light'
            }, {
                id: 'material.blue.light.compact',
                name: 'Material UI Compact'
            }
            ],
            epcEnvironments: []
        }
    },

    computed: {
        debugMode: {
            get: function () {
                if (!localStorage.dbpDebugMode)
                    localStorage.dbpDebugMode = 'false';
                return localStorage.dbpDebugMode === 'true';
            },
            set: function (newValue) {
                localStorage.dbpDebugMode = newValue.toString();
            }
        },
        theme: {
            get: function () {
                // force default
                //if (!localStorage.dbpTheme)
                localStorage.dbpTheme = 'material.blue.light';
                //console.log("get theme", localStorage.dbpTheme);
                return localStorage.dbpTheme;
            },
            set: function (newValue) {
                //console.log("set theme", newValue);
                localStorage.dbpTheme = newValue.toString();
                themes.current(newValue);
            }
        }
    },

    created() {
    },

    mounted() {
        const that = this;
        utils.callWebAPI("api/settings/get", {}, (data) => {
            that.uiData = data;
            that.uiData.debugMode = that.debugMode;
            that.uiData.theme = that.theme;
            that.saveButtonOptions.text = data.saveButtonText;
        });
    },

    methods: {
        testEPC() {
            const data = {
                EPCurl: this.uiData.EPCurl,
                EPCuser: this.uiData.EPCuser,
                EPCpassword: this.uiData.EPCpassword
            };

            const ui = this.uiData;
            utils.callWebAPI("api/settings/epctest", data, (d) => {
                if (d.TestResult)
                    utils.message("OK", 1);
                else
                    utils.message(d.TestMessage, 2);
                ui.EPCEnvironments = d.Environments;
            }, () => { }, 'POST');
        },

        restoreDefaultValues() {
            this.uiData.backgroundColor = this.defaultBackgroundColor;
            this.uiData.mandatoryColor = this.defaultMandatoryColor;
            this.uiData.readonlyTransparent = this.defaultReadonlyTransparent;
        },

        submit(e) {
            this.debugMode = this.uiData.debugMode;
            this.theme = this.uiData.theme;

            const data = {
                backgroundColor: this.uiData.backgroundColor,
                mandatoryColor: this.uiData.mandatoryColor,
                readonlyTransparent: this.uiData.readonlyTransparent,
                autoSaveEnabled: this.uiData.autoSaveEnabled,
                autoSaveInterval: this.uiData.autoSaveInterval,
                auditTrailEnabled: this.uiData.auditTrailEnabled,
                auditTrailEnabledDescription: this.uiData.auditTrailEnabledDescription,
                EPCurl: this.uiData.EPCurl,
                EPCuser: this.uiData.EPCuser,
                EPCpassword: this.uiData.EPCpassword,
                EPCEnvironment: this.uiData.EPCEnvironment
            };

            utils.callWebAPI("api/settings/set", data, () => {
                utils.message(this.uiData.savedMessage, 1);
            }, () => { }, 'POST');
            e.preventDefault();
        }
    }
}
